import { createTheme } from "@material-ui/core/styles";

const MAIN_MAPPING = {
  backgroundColor: "#FFFFFF",
  textColor: "#383538",
  borderColor: "#D6D6D6",
  hoverColor: "#E6E6E6",
  buttonBackgroundColor: "#555555",
  buttonTextColor: "#F8F8F8",
  backgroundBaseColor: "#777777",
};

const createAppTheme = (mode) =>
  createTheme({
    palette: {
      text: {
        primary: MAIN_MAPPING.textColor,
      },
    },
    background: MAIN_MAPPING.backgroundColor,
    hoverColor: MAIN_MAPPING.hoverColor,
    appBarBackground: MAIN_MAPPING.backgroundColor,
    appBarBorder: MAIN_MAPPING.borderColor,
    headerBackground: MAIN_MAPPING.backgroundColor,
    headerColor: MAIN_MAPPING.textColor,
    createContentBackground: MAIN_MAPPING.backgroundColor,
    secondaryButtonBackground: MAIN_MAPPING.backgroundColor,
    secondaryButtonColor: MAIN_MAPPING.textColor,
    primaryButtonBackground: MAIN_MAPPING.buttonBackgroundColor,
    primaryButtonColor: MAIN_MAPPING.buttonTextColor,
    finishModalBackground: MAIN_MAPPING.backgroundColor,
    initMessageBoxBackground: MAIN_MAPPING.backgroundColor,
    messageBoxBackground: MAIN_MAPPING.backgroundColor,
    sendFieldBackground: MAIN_MAPPING.backgroundColor,
    fieldBackground: MAIN_MAPPING.backgroundColor,
    statusBackground: MAIN_MAPPING.backgroundColor,
    statusColor: MAIN_MAPPING.textColor,
    selectedTabBackground: MAIN_MAPPING.buttonBackgroundColor,
    selectedTabColor: MAIN_MAPPING.buttonTextColor,
    boxShadow: "0",
    borderColor: MAIN_MAPPING.borderColor,
    border: `1px solid ${MAIN_MAPPING.borderColor}`,
    imageButton: "image.svg",
    inputBackground: MAIN_MAPPING.backgroundColor,
    ticketTypeBackground: [MAIN_MAPPING.backgroundColor],
    ticketTypeBorder: `1px solid ${MAIN_MAPPING.borderColor}`,
    ticketTypeColor: MAIN_MAPPING.textColor,
    tableFontColor: MAIN_MAPPING.textColor,
    createMode: "page",
    timeColor: MAIN_MAPPING.textColor,
    fontWeight: 700,
    inputBorder: `1px solid ${MAIN_MAPPING.borderColor}`,
    statusPadding: "4px 10px",
    textColor: MAIN_MAPPING.textColor,
    placeholderColor: MAIN_MAPPING.hoverColor,
    backgroundBaseColor: MAIN_MAPPING.backgroundBaseColor,
    adminIcon: "ic_alpacaBlacBigFace.svg",
  });

export default createAppTheme;
