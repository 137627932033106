import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

export const StyledAdminMessage = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, isRecalled }) => {
  return {
    borderRadius: 10,

    padding: 10,
    lineHeight: 1.2,
    maxWidth: 430,
    fontWeight: theme.fontWeight,
    position: "relative",
    fontSize: 15.5,
    display: "inline-block",
    wordBreak: "break-word",
    border: theme.border,
    background: theme.background,
    color: theme.textColor,
    ...(isRecalled && {
      opacity: 0.5,
    }),
  };
});

export const StyledMessageRow = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ status }) => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
    marginBottom: "18px",
    alignItems: status == "failed" ? "center" : "flex-end",
  };
});

export const StyledAdminMessageTime = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    textAlign: "left",
    marginLeft: 8,
    fontSize: 10,
    fontWeight: 400,
    color: theme.textColor,
  };
});

export const StyledUserMessageTime = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    textAlign: "right",
    marginRight: 8,
    fontSize: 10,
    fontWeight: 400,
    color: theme.textColor,
  };
});

export const StyledMsgField = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    background: theme.fieldBackground,
    padding: "8px 12px",
    marginRight: 10,
    color: theme.textColor,
    fontWeight: 400,
    fontSize: "12px",
    whiteSpace: "nowrap",
    border: theme.border,
    borderRadius: "5px",
  };
});

export const StyledMsgValue = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: 16,
    fontWeight: 700,
  };
});

export const StyledFileContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, end }) => {
  return {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: end ? "flex-end" : "flex-start",
  };
});

export const StyledFileBox = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    borderRadius: 5,
    width: "150px",
    padding: 5,
    height: 40,
    border: "1px solid #e6e6e6",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };
});

export const StyledMsgHeader = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: 14,
    fontWeight: 500,
  };
});

export const StyledAddressFrom = styled("span", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: 12,
    fontWeight: 500,
    marginLeft: "2px",
  };
});

export const StyledMsgAddress = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    fontSize: 16,
  };
});

export const StyledUserMessage = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, sending }) => {
  return {
    padding: 10,
    color: theme.headerColor,
    opacity: sending ? 0.5 : 1,
  };
});
export const StyledInitUserMessage = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    borderRadius: 10,
    padding: 0,
    lineHeight: 1.2,
    maxWidth: 430,
    fontWeight: theme.fontWeight,
    position: "relative",
    fontSize: 15.5,
    display: "inline-block",
    wordBreak: "break-word",
    background: theme.initMessageBoxBackground,
    padding: 0,
    border: theme.border,
    color: theme.textColor,
  };
});

export const StyledRecalledMsg = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => {
  return {
    color: theme.customColors.grey[500],
  };
});

export const SurveyScoreGroup = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  columnGap: 12,
  marginTop: 16,
  marginBottom: 0,

  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    rowGap: 16,
  },
}));

export const SurveyScoreItem = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: 4,
  padding: 8,
  borderRadius: 5,
  background: "#FAF6FF",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 0px 8px 0px #7B61FF66",
  },
  "&:active": {
    boxShadow: "0px 0px 8px 0px #7B61FF66",
    border: `1px solid ${theme.customColors.purple[500]}`,
  },
}));
