import React, { memo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./styles";
import _, { set } from "lodash";

const useStyles = makeStyles(styles);

const CustomTable = ({
  headers,
  contents,
  rowKeys,
  type = "table",
  leftText = { 0: true },
  style = {},
  selectedRow = [],
  sortDataFunction,
  rowSelected,
  noHead,
  collapse,
  disabledSort = {},
  orderBy,
  order,
  noDataTitle = "Create Data",
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  let titleClass = classes.tableTitle;
  let valueClass = classes.tableValue;
  if (type == "outlined") {
    titleClass = classes.outlinedTitle;
    valueClass = classes.outlinedValue;
  }
  if (type == "column") {
    titleClass = classes.columnTitle;
    valueClass = classes.columnValue;
  }
  let selectedStyle = rowSelected
    ? {
        cursor: "pointer",
        background: "#FFFFFF",
      }
    : {};

  const handleSort = (header) => {
    const direction = order === "asc" ? "desc" : "asc";
    sortDataFunction(header, direction);
  };

  return (
    <TableContainer className={classes.card}>
      <Table
        style={{
          borderCollapse: collapse ? "collapse" : "separate",
          borderSpacing: "0 5px",
        }}
      >
        {!noHead && (
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell
                    key={index}
                    align={leftText[index] == true ? "left" : "center"}
                    className={`${classes.title} ${titleClass}`}
                    style={{ ...style }}
                  >
                    {sortDataFunction && (
                      <TableSortLabel
                        active={orderBy === header}
                        className={`tableTitle`}
                        classes={{ icon: classes.icon }}
                        direction={order}
                        IconComponent={ExpandMoreIcon}
                        disabled={disabledSort[index]}
                        onClick={() => handleSort(header)}
                      >
                        {header}
                      </TableSortLabel>
                    )}
                    {!sortDataFunction && header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {_.map(contents, (row, index) => {
            const rowIndex = rowKeys ? rowKeys[index] : index;

            const isSelected = selectedRow.includes(rowIndex);
            return (
              <TableRow
                hover
                role="checkbox"
                className={classes.tableRow}
                key={rowIndex}
                style={selectedStyle}
                onClick={() => {
                  rowSelected && rowSelected(rowIndex);
                }}
              >
                {row &&
                  row.map((r, rIndex) => (
                    <TableCell
                      key={rIndex}
                      align={leftText[rIndex] == true ? "left" : "center"}
                      className={`${classes.value} ${valueClass} ${
                        isSelected ? classes.selected : ""
                      }`}
                      style={{ position: "relative", ...style }}
                    >
                      {rIndex !== 0 && (
                        <div
                          className={classes.absoluteBorderLeft}
                          style={{ left: -3, height: "50%", top: "25%" }}
                        />
                      )}
                      {r}
                    </TableCell>
                  ))}
              </TableRow>
            );
          })}
          {contents.length == 0 && (
            <TableRow>
              <TableCell
                colSpan={headers.length}
                style={{
                  borderBottom: "0px",
                  height: "60vh",
                  textAlign: "center",
                }}
              >
                <i
                  className="meta-crm-icon-ic_boxOpenLight font-size-44"
                  style={{ color: theme.borderColor }}
                />
                <div style={{ color: theme.borderColor, fontWeight: 600 }}>
                  {noDataTitle}
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(CustomTable);
