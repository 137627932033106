const CryptoJS = require("crypto-js");

function generateKey(address) {
  return CryptoJS.SHA256(address);
}

function encryptToken(token, key) {
  return CryptoJS.AES.encrypt(token, key.toString()).toString();
}

function decryptToken(encryptedToken, key) {
  let bytes = CryptoJS.AES.decrypt(encryptedToken, key.toString());
  return bytes.toString(CryptoJS.enc.Utf8);
}

module.exports = { generateKey, encryptToken, decryptToken };
