import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItems from "./MenuItems";
import { defaultThemeConfig } from "features/configure";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 30 },

  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: theme.appBarBackground,
    borderBottom: `1px solid ${theme.appBarBorder}`,
    boxShadow: "0px 0px 0px",
    zIndex: theme.zIndex.drawer + 1,
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
}));

const LandingHeader = ({ hidden, isCustomDomain, entityName, openConnect }) => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const { entityConfig } = useFetchEntityConfig();
  const companyLogo = _.get(entityConfig, "company.logo");

  if (hidden) {
    return <div />;
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid
            justifyContent="space-between"
            alignItems="center"
            container
            style={{ margin: "0 auto" }}
          >
            <Grid item>
              <div
                className="startRow"
                style={{
                  color: "#383538",
                  fontWeight: 700,
                  fontSize: 20,
                  padding: 10,
                }}
              >
                {entityConfig.company && (
                  <img
                    src={
                      companyLogo ? companyLogo : defaultThemeConfig.headerLogo
                    }
                    style={{
                      height: computer ? "30px" : "15px",
                      marginRight: computer ? 20 : 5,
                      objectFit: "contain",
                      verticalAlign: "middle",
                    }}
                  />
                )}
                <div
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "17px",
                    color: theme.selectedTabBackground,
                    display: "inline",
                  }}
                ></div>
              </div>
            </Grid>

            <Grid item>
              <MenuItems
                openConnect={openConnect}
                entityName={entityName}
                isCustomDomain={isCustomDomain}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default LandingHeader;
