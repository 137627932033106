import React, { useState, useEffect, useContext } from "react";
import { Modal } from "@material-ui/core";
import { Loading } from "features/common/Loading";
import _ from "lodash";
import { makeStyles, useTheme } from "@mui/styles";

import { useFetchEntityConfig, useFetchTicketList } from "./redux/hooks";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useParams, useOutletContext } from "react-router-dom";
import TicketList from "./TicketList";
import { Helmet } from "react-helmet";
import NoAuth from "./components/NoAuth";
import { defaultThemeConfig } from "features/configure";

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    modalPaper: {
      // width: "80%",
      margin: 10,
      width: 485,
      height: 385,
      // padding: "70px 20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
      textAlign: "center",
      backgroundColor: theme.background,
      borderRadius: theme.borderRadius,
      border: theme.border,
    },
  };
});

function MessageAdmin() {
  const [entityName, isCustomDomain] = useOutletContext();

  const { auth } = useConnectWallet();
  const classes = useStyles();
  const {
    fetchTicketList,
    fetchTicketListDone,
    fetchTicketListPending,
    filter,
    setFilter,
    ticketList,
  } = useFetchTicketList();
  const { entityConfig } = useFetchEntityConfig();
  const companyLogo = _.get(entityConfig, "company.logo");
  const [openModal, setOpenModal] = useState(!auth);

  useEffect(() => {
    if (auth) setOpenModal(false);
  }, [auth]);

  useEffect(() => {
    if (auth) {
      fetchTicketList({ entityName });

      const id = setInterval(async () => {
        fetchTicketList({ entityName });
      }, 15000);
      return () => clearInterval(id);
    }
  }, [auth, entityName]);

  const renderView = () => {
    if (!fetchTicketListPending && !fetchTicketListDone) {
      return <NoAuth />;
    }
    return <TicketList />;
  };

  const renderMetadata = (
    title = "MetaCRM Support Center",
    desc = "Made with ♥️ in MetaVerse",
    url,
    imageURL,
    bellIntegration = false
  ) => {
    if (!imageURL) {
      imageURL =
        window.location.origin + require("assets/img/defaultBanner.png");
    }
    if (process.env.REACT_APP_ENTITY == "curve") {
      imageURL =
        window.location.origin + require("assets/img/curveBanner.png").default;
      title = "Curve Support Center";
      desc = "Powered by MetaCRM";
    } else if (process.env.REACT_APP_ENTITY == "radiant") {
      imageURL =
        window.location.origin +
        require("assets/img/radiantBanner.png").default;
      title = "Radiant Support Center";
      desc = "Powered by MetaCRM";
    }
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="fragment" content="!" />
        <meta itemprop="name" content={title} />
        <meta itemprop="description" content={desc} />
        <meta itemprop="image" content={imageURL} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={imageURL} />
        {bellIntegration && (
          <script type="text/javascript">{`
          !(function (d, s, id, src) {
            var js; var fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id; js.src = src; js.defer = true;
            fjs.parentNode.insertBefore(js, fjs);
          })(
            document,'script','widget-dom-id',
            'https://beta.widget.metacrm.inc/static/js/widget.js'
          );
        `}</script>
        )}
      </Helmet>
    );
  };

  return (
    <div>
      {/* {renderLoadingModal()} */}
      <Loading
        open={
          fetchTicketListPending &&
          !fetchTicketListDone &&
          ticketList.length == 0
        }
      />
      {renderMetadata(
        _.upperFirst(entityName) + " Support Center",
        "Powered by MetaCRM",
        companyLogo || defaultThemeConfig.headerLogo,
        null,
        process.env.REACT_APP_BELL_INTEGRATION
      )}

      {renderView()}
    </div>
  );
}

export default MessageAdmin;
