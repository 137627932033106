const styles = (theme) => ({
  title: () => ({
    fontSize: 12.5,
    fontWeight: 700,
    padding: "5px 8px 19px 14px",
    fontFamily: "Inter",
    // "&:first-child": {
    //   borderTopLeftRadius: 10,
    //   borderBottomLeftRadius: 10,
    // },
    // "&:last-child": {
    //   borderTopRightRadius: 10,
    //   borderBottomRightRadius: 10,
    // },
  }),
  tableTitle: (props) => {
    return {
      backgroundColor: "transparent",
      borderBottom: props.border,

      fontWeight: 600,
      color: props.tableHeaderColor || props.tableFontColor,

      "& .tableTitle": {
        "&:hover": {
          color: props.borderColor,
        },
        "&.MuiTableSortLabel-active": {
          "& .MuiSvgIcon-root": {
            color: `${props.borderColor} !important`,
          },
        },
      },
    };
  },
  outlinedTitle: () => ({
    fontSize: 15,
    backgroundColor: "#ffffff",
    color: "#0096FF",
    borderRight: `0.7px solid rgba(56, 56, 56, 0.2)`,
    borderBottom: `2px solid #0096FF`,
  }),

  lumnTitle: (props) => {
    return {
      borderBottom: `0px solid ${props.palette.text.primary}`,
      borderRight: `0px solid ${props.palette.text.primary}`,
    };
  },
  value: (props) => {
    return {
      fontSize: 12,
      fontWeight: 700,
      color: props.tableFontColor,
      backgroundColor: props.background,
      // "&:first-child": {
      //   borderTopLeftRadius: 10,
      //   borderBottomLeftRadius: 10,
      // },
      // "&:last-child": {
      //   borderTopRightRadius: 10,
      //   borderBottomRightRadius: 10,
      // },
    };
  },
  tableRow: () => ({
    "&:hover": {
      // border: "1px solid blue !important",
    },
  }),

  card: () => ({
    flexGrow: 1,
    backgroundColor: "transparent",
    overflow: "auto",
    padding: "15px 0",
  }),
  tableValue: (props) => {
    return {
      padding: "5px 8px 5px 14px",
      color: props.tableFontColor,
      borderBottom: props.border + " !important",
      fontFamily: "Inter",
      // borderTop: "1px solid rgba(165, 165, 165, 0.5)",
      // borderBottom: "1px solid rgba(165, 165, 165, 0.5)",
      "&:first-child": {
        // borderLeft: "1px solid rgba(165, 165, 165, 0.5)",
      },
      "&:last-child": {
        // borderRight: "1px solid rgba(165, 165, 165, 0.5)",
      },
    };
  },
  outlinedValue: () => ({
    border: `0.7px solid rgba(56, 56, 56, 0.2)`,
    padding: 5,
    "&:first-child": {
      borderLeft: "0px",
    },
    // "&:last-child": {
    //   borderRight: "0px",
    // },
  }),
  columnValue: (props) => {
    return {
      borderRight: `0px solid ${props.palette.text.primary}`,
      borderBottom: "none",
    };
  },
  selected: (props) => {
    return {
      background: props.hoverColor,
    };
  },

  absoluteBorderLeft: (props) => {
    return {
      borderLeft: props.border,
      position: "absolute",
      left: 0,
      top: "20%",
      height: "60%",
    };
  },
});

export default styles;
