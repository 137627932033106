import React from "react";
import _ from "lodash";
import { Modal, MenuItem, CircularProgress } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";

export const Loading = ({ open, text, fullScreen = true, popover, white }) => {
  const theme = useTheme();
  if (popover) {
    return (
      <Modal className="modal" open={open}>
        <div
          style={{
            background: theme.background,
            borderRadius: 10,
            width: 180,
            textAlign: "center",
            padding: 48,
          }}
        >
          {/* <img
            src={require("assets/img/loading.gif").default}
            style={{ height: 70, width: 70 }}
          /> */}
          <LoadingComponent />
          <div
            style={{ fontSize: 18, fontWeight: 700, color: theme.textColor }}
          >
            {text || "Loading"}
          </div>
        </div>
      </Modal>
    );
  }
  if (fullScreen)
    return (
      <Modal open={open} className={"modal"}>
        <div className={"loadingPaper"}>
          <div>
            {/* <img
            src={require("assets/img/logo.svg").default}
            style={{ height: 70, objectFit: "contain" }}
          /> */}
          </div>
          <div style={{ marginTop: 20 }}>
            {/* <CircularProgress /> */}
            {/* <img
              src={require("assets/img/loading.gif").default}
              style={{ height: 100, width: 100 }}
            /> */}
            <LoadingComponent />
          </div>
          <h4>{text}</h4>
        </div>
      </Modal>
    );
  return (
    <div
      style={{
        visibility: open ? "visible" : "hidden",
        height: "100%",
        background: theme.background,
        width: "100%",
        position: "absolute",
        zIndex: 100,
        textAlign: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        {/* <img
          src={require("assets/img/loading.gif").default}
          style={{ height: 70, width: 70 }}
        /> */}
        <LoadingComponent loadingText={text} />
        <div style={{ fontSize: 20, fontWeight: 600, color: theme.textColor }}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default Loading;
