import React, { useState, useEffect, useContext } from "react";
import { Grid, IconButton } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Close from "@material-ui/icons/Close";
import _, { capitalize } from "lodash";
import { makeStyles, useTheme } from "@mui/styles";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import { useNavigate } from "react-router-dom";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useOutletContext } from "react-router-dom";
import TicketType from "./components/TicketType";
import { useFetchEntityConfig, useFetchTicketList } from "./redux/hooks";
import ClearIcon from "@material-ui/icons/Clear";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { useSelector } from "react-redux";
import { selectTicketTypeForDropdown } from "features/metadesk/redux/entityConfig.selector";
import { parseCustomDomainUrl } from "features/helpers/utils";
import { apiUrl } from "features/configure";
import { IconFileUploader } from "@metacrm/metacrm-material-ui/dist/FileUploader";
import PreviewFile from "@metacrm/metacrm-material-ui/dist/PreviewFile";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  messageDetail: () => ({
    background: "#FFFFFF",
    padding: 10,
    height: "calc(100vh - 137px)",
    margin: 10,
  }),
  inboxDescriptionLabel: () => ({
    textAlign: "left",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#383538",
  }),
  selected: () => ({
    "&.Mui-selected": {
      backgroundColor: `#A5A5A5`,
    },
  }),
  bg: () => ({
    maxWidth: theme.createMode == "modal" ? 500 : 800,
    margin: "10px auto",
  }),
  finishModal: () => ({
    background: theme.finishModalBackground,
    color: theme.headerColor,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "289px",
    padding: 50,
    height: 350,
    borderRadius: 10,
  }),
  privacyModal: () => {
    return {
      background: theme.finishModalBackground,
      width: "363px",
      borderRadius: "10px",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "60px 24px",
      position: "relative",
      color: theme.textColor,

      "& .privacy-title": {
        fontSize: "22px",
        margin: 0,
        marginBottom: "24px",
      },

      "& .privacy-close-icon": {
        position: "absolute",
        top: "16px",
        right: "16px",
        cursor: "pointer",
        color: theme.primaryButtonBackground,
      },

      "& .privacy-text": {
        textAlign: "center",
        margin: 0,
        marginBottom: "24px",
        lineHeight: "21px",
      },
      "& .privacy-button": {
        width: "193px",
        fontWeight: "bold",
      },
    };
  },
  header: () => ({
    backgroundColor: theme.background,
    padding: 20,
    textAlign: theme.createMode == "modal" ? "left" : "center",
    color:
      theme.createMode == "modal"
        ? theme.selectedTabBackground
        : theme.headerColor,
    fontWeight: 700,
    fontSize: 20,
    borderRadius: `10px 10px 0px 0px`,
    border: theme.border,
    borderBottom: theme.border,
  }),
  content: () => ({
    padding: 20,
    background: theme.createContentBackground,
    borderRadius: `0px 0px 10px 10px`,
    border: theme.border,
    borderTop: "0px",
  }),
  privacyArea: () => ({
    display: "flex",
    alignItems: "center",
    margin: "35px 0 19px",
    marginLeft: "-9px",
    color: theme.textColor,

    "& .policy-btn": {
      color: "#0096FF",
      textDecoration: "underline",
      textDecorationSkipInk: "none",
    },
  }),
}));

const CreateTicket = ({ close }) => {
  const [entityName, isCustomDomain] = useOutletContext();

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [issue, setIssue] = useState("");
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const [ticketLoading, setTicketLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const navigate = useNavigate();
  const { socket, userData, sendMessage } = useConnectWallet();
  const { entityConfig } = useFetchEntityConfig();
  const classes = useStyles();
  const addrs = _.get(userData, "address", "");
  const { createTicket } = useFetchTicketList();
  const privacyPolicyCheckedRequired = _.get(
    entityConfig,
    "privacyPolicyCheckedRequired"
  );

  const ticketTypeForDropdown = useSelector(selectTicketTypeForDropdown);

  const uploadFiles = async () => {
    if (files && files.length > 0) {
      let postFormData = new FormData();
      files.forEach((fileItem) => {
        postFormData.append("chat", fileItem);
      });

      postFormData.append("entityName", entityName);
      const uploadResult = await axios.post(
        apiUrl + "api/gcp/uploadChat",
        postFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const chatImages = _.get(uploadResult.data, "chat");
      return chatImages;
    }
    return [];
  };

  const doCreateTicket = async () => {
    if (title && desc && issue) {
      setError("");
      setTicketLoading(true);
      try {
        const filesResult = await uploadFiles();

        const result = await createTicket({
          type: issue,
          title: title,
          description: desc,
          entityName: entityName,
          imagesUrl: filesResult,
        });
        if (result) {
          setTicketLoading(false);
          setSuccess(true);

          socket.emit("clientCreateTicket", {
            ticket: result,
          });
        }
      } catch (error) {
        alert(error);
        setTicketLoading(false);
      }
    } else {
      setError("Please select issue and type title and description.");
    }
  };

  const handleChangePrivacyPlice = (event) => {
    setPrivacyChecked(event.target.checked);
  };

  const handleOpenPrivacy = (event) => {
    event.preventDefault();
    setPrivacyOpen(true);
  };

  const handleAgree = () => {
    setPrivacyChecked(true);
    setPrivacyOpen(false);
  };

  function handleOnchange(file) {
    setFiles((prev) => [...prev, file]);
  }

  return (
    <div className={classes.bg}>
      <Modal open={success} className={"modal"}>
        <div className={classes.finishModal}>
          <i
            className="meta-crm-icon-ic_ticketCreatedSuccess font-size-32"
            style={{ fontSize: "100px" }}
          />
          <h2 style={{ marginTop: "24px", fontWeight: 500, fontSize: "16px" }}>
            Your ticket has been created.
          </h2>
          <Button
            style={{ marginTop: "50px", height: "28px" }}
            color="primary"
            onClick={() => {
              if (close) close();
              else {
                navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/"));
              }
            }}
          >
            {close ? "Close" : "Back to ticket list"}
          </Button>
        </div>
      </Modal>
      {privacyPolicyCheckedRequired && (
        <Modal
          open={isPrivacyOpen}
          onClose={() => setPrivacyOpen(false)}
          className={"modal"}
        >
          <div className={classes.privacyModal}>
            <Close
              className={"privacy-close-icon"}
              onClick={() => setPrivacyOpen(false)}
            />
            <h2 className={"privacy-title"}>Privacy Policy </h2>
            <p className={"privacy-text"}>
              Upon submitting a ticket to the {capitalize(entityName)} Support
              Staff via the MetaCRM platform, I authorize the{" "}
              {capitalize(entityName)} team to use this software to check my
              balance and transaction history in order to effectively provide
              troubleshooting and diagnosis. All data will be retained for
              reference and training purposes, unless requested by the user.
            </p>
            <Button
              color="primary"
              className={"privacy-button"}
              onClick={handleAgree}
            >
              I agree
            </Button>
          </div>
        </Modal>
      )}
      {!close && (
        <div
          className="startRow"
          style={{
            color: theme.textColor,
            padding: 0,
            cursor: "pointer",
            fontWeight: 700,
            fontSize: 16,
            marginBottom: 20,
          }}
          onClick={() => {
            {
              navigate(-1);
            }
          }}
        >
          Ticket List
          <i
            className="meta-crm-icon-ic_redo font-size-14"
            style={{ marginLeft: "10px" }}
          />
        </div>
      )}
      <div>
        <div className={`${classes.header} ${close && "betweenRow"}`}>
          Create Ticket
          {close && (
            <IconButton onClick={() => close()} style={{ padding: 0 }}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.content}>
          <CustomDropdown
            data={ticketTypeForDropdown}
            placeholder={"Select Issue"}
            value={issue}
            onSelect={(e) => setIssue(e.target.value)}
            style={{
              width: "40%",
              border: theme.createSelectBorder || "",
              borderRadius: theme.borderRadius,
            }}
          />
          <div style={{ color: "#FC5555", padding: 10 }}>{error}</div>
          <div>
            <CustomOutlinedInput
              fullWidth
              noLine
              placeholder="Type title here..."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <CustomOutlinedInput
              fullWidth
              multiline
              noLine
              rows={8}
              placeholder="Type your question here..."
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
            <Box my={2}>
              <PreviewFile
                files={files}
                onDelete={(file, index) => {
                  const newFiles = [...files];
                  newFiles.splice(index, 1);
                  setFiles(newFiles);
                }}
              />
            </Box>
            <Box my={2}>
              <IconFileUploader
                sx={{
                  color: theme.textColor,
                  borderColor: theme.borderColor,
                }}
                uploadFile={(files) => setFiles((prev) => [...prev, ...files])}
                inputProps={{
                  accept: `image/jpeg,
                    image/jpg,
                    image/png,
                    image/webp,
                    image/heic,
                    image/jfif,
                    image/gif,
                    application/pdf,
                    video/mp4,
                    video/quicktime`,
                }}
              />
            </Box>
          </div>
          {privacyPolicyCheckedRequired && (
            <div className={classes.privacyArea}>
              <CustomCheckbox
                checked={privacyChecked}
                onChange={handleChangePrivacyPlice}
              />
              <div>
                I have read and agree the{" "}
                <a
                  href="/"
                  style={{ color: theme.textColor }}
                  onClick={handleOpenPrivacy}
                  className="policy-btn"
                >
                  privacy policy
                </a>
                .
              </div>
            </div>
          )}

          <div style={{ marginTop: 20 }} className="betweenRow">
            <div style={{ textAlign: "right" }}>
              {privacyPolicyCheckedRequired ? (
                <Button
                  color="primary"
                  style={{ width: 168, height: 36, borderRadius: 3, margin: 0 }}
                  disabled={ticketLoading || !privacyChecked}
                  onClick={() => doCreateTicket()}
                >
                  Create
                </Button>
              ) : (
                <Button
                  color="primary"
                  style={{ width: 140, margin: 0 }}
                  disabled={ticketLoading}
                  onClick={() => doCreateTicket()}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTicket;
