import React from "react";
import { makeStyles, useTheme } from "@mui/styles";

import LandingHeader from "./components/LandingHeader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";

const Landing = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <LandingHeader />
    </div>
  );
};

export default Landing;
