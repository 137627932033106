import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { pools, rewardToCoingeckoIdMap } from '../configure';
import _ from "lodash";

const coingeckoIds = _.concat(_.map(pools, "coingeckoId"), Object.values(rewardToCoingeckoIdMap))
export function fetchPrice() {
    return dispatch => {
      dispatch({
        type: FETCH_PRICE_BEGIN,
      });
  
      const promise = new Promise(async (resolve, reject) => {
        try {
          const request = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${coingeckoIds}&vs_currencies=usd`);
          const result = await request.json();

          dispatch({
            type: FETCH_PRICE_SUCCESS,
            data: result
          });
        } catch (err) {
          dispatch({
            type: FETCH_PRICE_FAILURE,
          });
        }
      });
  
      return promise;
    };
  }

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData, chart } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData,
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData
  };
}

export function reducer(state = { fetchPricePending: false, priceData: {}}, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:
      return {
        ...state,
        priceData: action.data,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}