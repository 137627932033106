import React, { useEffect, useState, useRef } from "react";
import { makeStyles, useTheme } from "@mui/styles";

import { StyledCircularProgress } from "./LoadingComponent.styles";

const LoadingComponent = ({ loadingText }) => {
  const customTheme = useTheme();
  return (
    <>
      <StyledCircularProgress customTheme={customTheme} />
      <div
        style={{
          color: customTheme.textColor,
          marginTop: "7px",
          fontSize: "12px",
        }}
      >
        {loadingText}
      </div>
    </>
  );
};

export default LoadingComponent;
