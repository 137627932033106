import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ENTITY_CONFIG_FETCH_SUCCESS } from "./constants";
import axios from "axios";
import _ from "lodash";
import { apiUrl } from "features/configure";
import { useSnackbar } from "features/common/redux/snackbar";
export function fetchEntityConfig({ entityName }) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        let types = await axios.get(
          `${apiUrl}api/entity/entityConfig?entityName=` + entityName
        );
        dispatch({
          type: ENTITY_CONFIG_FETCH_SUCCESS,
          data: types.data,
        });
        resolve();
      } catch (err) {
        reject(err);
        console.log(err);
      }
    });

    return promise;
  };
}

export function useFetchEntityConfig() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { entityTheme, entityConfig } = useSelector(
    (state) => ({
      entityConfig: state.metadesk.entityConfig,
      entityTheme: state.metadesk.theme,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    async (data) => {
      try {
        return await dispatch(fetchEntityConfig(data));
      } catch (err) {
        enqueueSnackbar({
          message: _.get(err, "response.data.error", "ERROR"),
          options: {
            variant: "error",
          },
        });
      }
    },
    [dispatch]
  );

  return {
    entityConfig,
    entityTheme,
    fetchEntityConfig: boundAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ENTITY_CONFIG_FETCH_SUCCESS:
      return {
        ...state,
        entityConfig: action.data,
        theme: action.data.theme,
      };

    default:
      return state;
  }
}
