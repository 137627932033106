import React, { useState, useEffect, useRef } from "react";
import { Grid, Modal, useMediaQuery } from "@material-ui/core";
import _ from "lodash";
import { makeStyles, useTheme } from "@mui/styles";
import CustomTabs from "components/CustomTabs/Tabs";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Button from "components/CustomButtons/Button";
import { container } from "assets/jss/material-kit-pro-react.js";
import { useFetchTicketList, useFetchEntityConfig } from "./redux/hooks";
import { useNavigate, useOutletContext } from "react-router-dom";
import TicketTable from "./components/TicketTable";
import Chat from "./Chat";
import TicketType from "./components/TicketType";
import CreateTicket from "./CreateTicket";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { useSelector } from "react-redux";
import { selectTicketTypeForDropdown } from "features/metadesk/redux/entityConfig.selector";
import { parseCustomDomainUrl } from "features/helpers/utils";

const drawerWidth = "15rem";

const TicketList = () => {
  const [entityName, isCustomDomain] = useOutletContext();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const { auth } = useConnectWallet();
  const statusMap = ["ALL", "NEW", "ONGOING", "DONE"];
  const [issue, setIssue] = useState("All");
  const [search, setSearch] = useState("");
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [openCreate, setOpenCreate] = useState(false);
  const navigate = useNavigate();
  const { filter, setFilter, filterTicketList, cleanData } =
    useFetchTicketList();
  const ticketTypeForDropdown = useSelector(selectTicketTypeForDropdown);

  useEffect(() => {
    if (!auth) {
      cleanData();
      setSelectedTicket("");
    }
  }, [auth]);
  useEffect(() => {
    setFilter({ ...filter, status: statusMap[selectedTab] });
  }, [selectedTab]);
  useEffect(() => {
    setFilter({ ...filter, issue: issue == "All" ? "" : issue });
  }, [issue]);
  useEffect(() => {
    setFilter({ ...filter, search: search });
  }, [search]);

  return (
    <div
      style={{
        maxWidth: 1300,
        margin: "0px auto",
      }}
    >
      <Modal
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        className="modal"
      >
        <div style={{ maxWidth: 500, width: "80%" }}>
          <CreateTicket close={() => setOpenCreate(false)} />
        </div>
      </Modal>
      <Grid container style={{ paddingBottom: 20 }} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <div
            style={{
              background: theme.background,
              border: theme.border,
              borderRadius: theme.borderRadius,
              height: "100%",
            }}
          >
            <div style={{ padding: "15px 14px 0" }}>
              <div className="betweenRow">
                <CustomOutlinedInput
                  fontSize={12}
                  noLine
                  style={{
                    maxWidth: "400px",
                    height: "36px",
                    padding: "0 0 0 7px",
                    borderRadius: "3px",
                  }}
                  placeholder="Search ticket title"
                  fullWidth
                  value={search}
                  startAdornment={
                    <i className="meta-crm-icon-ic_search font-size-20" />
                  }
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div style={{ textAlign: "right" }}>
                  <Button
                    color="primary"
                    style={{
                      margin: "0 0 0 40px",
                      height: "36px",
                      borderRadius: "3px",
                    }}
                    onClick={() => {
                      if (theme.createMode == "modal") {
                        setOpenCreate(true);
                      } else {
                        navigate(
                          parseCustomDomainUrl(
                            isCustomDomain,
                            entityName,
                            "/create"
                          )
                        );
                      }
                    }}
                  >
                    Create Ticket{" "}
                    <i
                      className="meta-crm-icon-ic_plusCircle font-size-20"
                      style={{ marginLeft: "9px", fontWeight: 300 }}
                    />
                  </Button>
                </div>
              </div>
              <div
                className={computer ? "startRow" : ""}
                style={{ marginTop: 20, alignItems: "flex-start" }}
              >
                <CustomTabs
                  initIndex={0}
                  tabIndex={selectedTab}
                  style={{
                    margin: "0 8px 0 0",
                    width: "fit-content",
                    minWidth: "fit-content",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  handleChange={(e, newValue) => setSelectedTab(newValue)}
                  tabs={statusMap.map((s) => {
                    return {
                      label: s,
                    };
                  })}
                />

                <div>
                  <CustomDropdown
                    fullWidth
                    sm
                    data={[
                      ...ticketTypeForDropdown,
                      { label: "ALL", value: "All" },
                    ]}
                    placeholder={"SELECT ISSUE"}
                    value={issue}
                    onSelect={(e) => setIssue(e.target.value)}
                    style={{ minWidth: 200 }}
                  />
                </div>
              </div>
            </div>

            {filterTicketList && (
              <div style={{ maxHeight: "66vh", overflow: "auto" }}>
                <TicketTable
                  ticketList={filterTicketList}
                  selectedRow={selectedTicket ? [selectedTicket] : []}
                  rowSelected={(ticketId) => {
                    setSelectedTicket(ticketId);
                  }}
                  entityName={entityName}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Chat
            selectedTicket={_.find(filterTicketList, { _id: selectedTicket })}
            entityName={entityName}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TicketList;
