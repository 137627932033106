import { styled } from "@mui/material/styles";
import { Popover } from "@material-ui/core";
import CustomListItem from "components/CustomListItem/CustomListItem";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledMenu = styled(Popover, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, customTheme }) => {
  return {
    "& .MuiPaper-root": {
      background: customTheme.background,
      color: customTheme.headerColor,
      border: customTheme.border,
      width: "210px",
      borderRadius: "5px",
    },
  };
});

export const StyledCustomListItem = styled(CustomListItem, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, customTheme }) => ({
  color: customTheme.headerColor,
  fontSize: "12px",
  fontWeight: "500",

  "&:hover": {
    backgroundColor: customTheme.hoverColor,
  },

  "& i": {
    fontSize: "14px",
  },

  "& .custom-list-item-label": {
    display: "flex",
    alignItems: "center",
  },

  "& .meta-crm-icon-ic_signOut": {
    marginRight: "10px",
  },
}));
