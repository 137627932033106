import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";

import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const useStyles = makeStyles((theme) => ({
  field: (props) => {
    return {
      padding: "7.5px 0",
      width: "100%",
      // margin:"0 3px",
      color: "white",
      display: "block",
      backgroundColor: props.inputBackground,
      overflow: "visible",
      // textAlign: "center",
      position: "relative",
      border: props.inputBorder,
      borderRadius: props.borderRadius.radiusNormal,
      boxShadow: props.boxShadow,
      fontFamily: "Inter",
    };
  },
  inputRoot: () => ({
    color: "white",
  }),
  showDetail: (props) => {
    return {
      justifyContent: "space-around",
      width: "100%",
      color: "#383538",
      // borderBottom: `1px solid #141314`,
      backgroundColor: "transparent",
      fontWeight: props.fontWeight.MEDIUM,
      "& .MuiInputBase-root": {
        color: props.textColor,
        fontSize: 16,
        FontFamily: "Inter",

        "& i": {
          color: props.textColor,
          opacity: 0.5,
        },
      },

      "& .MuiInputBase-input": {
        "&::placeholder": {
          color: props.textColor,
          opacity: 0.5,
        },
      },
      "& fieldset": { color: "#383538" },
      "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "transparent",
        color: "#383538",
        border: `none`,
        "& fieldset": {
          border: `none`,
        },
      },
      outline: "none",
      padding: "3px 10px",
    };
  },
  center: () => ({
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  }),
  option: () => ({
    marginTop: 8,
    "& .MuiInputBase-root": {
      color: "#383538",
    },
  }),

  hovered: () => ({
    "& fieldset": {},
  }),
  focused: () => ({
    "& fieldset": {},
  }),
  number: () => ({
    background: "#FFFFFF",
    borderRadius: 5,
    border: "1px solid #A5A5A5",
    padding: "5px 10px",
  }),
  noLine: () => ({
    border: "0px",
  }),
  sm: () => ({
    padding: "2px 5px",
    "& .MuiInputBase-root": {
      fontSize: 13,
    },
  }),
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowEmptyFormatting={true}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const {
    classes,
    disabled,
    value,
    placeholder,
    center,
    option,
    onChange,
    startAdornment,
    sm,
    endAdornment,
    onBlur,
    noLine,
    number,
    max,
    innerRender,
    style = {},
    multiline = false,
  } = props;
  const theme = useTheme();
  const commonStyle = useStyles(theme);
  const commonClasses = {
    root: `${commonStyle.showDetail} ${center && commonStyle.center} ${
      sm && commonStyle.sm
    } ${option && commonStyle.option} ${number && commonStyle.number} ${
      noLine && commonStyle.noLine
    }`,
    disabled: commonStyle.disabled,
  };

  if (disabled) {
    return (
      <div style={{ textAlign: center ? "center" : "left" }}>
        <div
          style={{
            fontSize: sm ? 12 : 18,
            paddingLeft: 5,
            paddingTop: 5,
            fontWeight: 700,
            color: option ? "#383538" : "#383538",
          }}
        >
          {value}
        </div>
      </div>
    );
  }
  let inputProps = {
    startAdornment: props.startAdornment,
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end" className={commonStyle.inputAdornment}>
        {endAdornment}
      </InputAdornment>
    ),
  };
  if (startAdornment) inputProps["startAdornment"] = startAdornment;
  if (number) inputProps["inputComponent"] = NumberFormatCustom;
  // if (multiline) inputProps["inputComponent"] = AutoSizeCustom;
  return (
    <div className={commonStyle.field} style={style}>
      <TextField
        {...props}
        // type={number ? "number" : ""}
        placeholder={placeholder || "0.0"}
        classes={Object.assign({}, commonClasses, classes)}
        onBlur={(e) => {
          onChange({ target: { value: _.trim(e.target.value) } });
          onBlur && onBlur();
        }}
        color="secondary"
        InputProps={inputProps}
      />
      {max && (
        <span style={{ position: "absolute", right: 0, color: "#383538" }}>
          {_.get(value, "length", 0)}/{max}
        </span>
      )}
      {innerRender}
    </div>
  );
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};
