import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles, useTheme } from "@mui/styles";
import CustomTable from "components/CustomTable/CustomTable";
import moment from "moment";
import TicketType from "./TicketType";
import { useFetchTeamMember, useFetchTicketList } from "../redux/hooks";
import Channel from "./Channel";
import TicketStatus from "./TicketStatus";
const useStyles = makeStyles((theme) => ({
  timestampLg: {
    fontSize: 12,
    paddingRight: 15,
    color: theme.timeColor,
    textAlign: "right",
    fontWeight: 400,
  },
  message: {
    color: theme.tableFontColor,
    fontWeight: theme.fontWeight,
  },
}));

const TicketTable = ({ ticketList, userId, rowSelected, selectedRow }) => {
  const classes = useStyles();
  const theme = useTheme();
  // const [orderBy, setOrderBy] = useState("");
  const { orderData, setOrder } = useFetchTicketList();
  // const [order, setOrder] = useState("desc");
  // const [sortedDetail, setSortedDetail] = useState(ticketList);

  const handleSort = (header, direction) => {
    const dir = direction || orderData.order;
    const by = header || orderData.orderBy;
    setOrder({ orderBy: by, order: dir }, userId);
  };
  const getRowValue = {
    Status: (ticket) => <TicketStatus ticket={ticket} />,

    "Ticket Title / Issue Type": (ticket) => (
      <div style={{ minWidth: 200 }}>
        <div
          style={{
            fontWeight: theme.fontWeight,
            fontSize: 14,
          }}
        >
          {ticket.title}
        </div>
        <TicketType ticketType={ticket.type} />
      </div>
    ),
    "Last Message": (ticket) => (
      <div className={classes.message}>
        {_.get(ticket, "lastMessage.created", "") && (
          <div
            style={{ fontSize: 10, textAlign: "left" }}
            className={classes.timestampLg}
          >
            Last:{" "}
            {moment(_.get(ticket, "lastMessage.created", "")).format(
              "YYYY/MM/DD HH:mm"
            )}
          </div>
        )}
        <div style={{ fontSize: 14, fontWeight: 400 }}>
          {" "}
          {_.truncate(_.get(ticket, "lastMessage.message", ""), { length: 60 })}
        </div>
      </div>
    ),

    Action: (ticket) => <div />,
    Create: (ticket) => (
      <div className={classes.timestampLg} style={{ textAlign: "left" }}>
        {moment(_.get(ticket, "created", "")).format("YYYY/MM/DD")}
        <div>{moment(_.get(ticket, "created", "")).format("HH:mm")}</div>
      </div>
    ),
  };

  let headers = [
    "Status",
    "Ticket Title / Issue Type",
    "Last Message",
    "Create",
  ];

  return (
    <CustomTable
      leftText={{
        0: true,
        1: true,
        2: true,
      }}
      rowKeys={_.map(ticketList, "_id")}
      headers={headers}
      collapse
      sortDataFunction={handleSort}
      orderBy={orderData.orderBy}
      order={orderData.order}
      noDataTitle="Create Ticket"
      rowSelected={rowSelected}
      selectedRow={selectedRow}
      contents={_.map(ticketList, (ticket) => {
        return _.map(headers, (header) => getRowValue[header](ticket));
      })}
    />
  );
};

export default TicketTable;
