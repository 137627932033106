const configs = {
  local: {
    apiUrl: "http://localhost:21667/",
    socketIOUrl: "http://localhost:21667/",
    sessionNumOfHours: 24,
  },
  dev: {
    apiUrl: "https://api-dev.metacrm.inc/",
    socketIOUrl: "https://api-dev.metacrm.inc/",
    sessionNumOfHours: 24,
  },
  demo: {
    apiUrl: "https://api-demo.metacrm.inc/",
    socketIOUrl: "https://api-demo.metacrm.inc/",
    sessionNumOfHours: 24,
  },
  production: {
    apiUrl: "https://api-desk.metacrm.inc/",
    socketIOUrl: "https://api-desk.metacrm.inc/",
    sessionNumOfHours: 1,
  },
};

const environment = process.env.REACT_APP_ENV || "local";

export default configs[environment];
