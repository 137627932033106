import React from "react";
import _ from "lodash";
import LandingHeader from "features/home/components/LandingHeader";

const UnderMaintenance = ({}) => {
  return (
    <div
      className="loadingPaper"
      style={{
        background: "white",
        textAlign: "center",
        height: "calc(100vh - 60px)",
      }}
    >
      <LandingHeader />
      <div>
        <img src={require("assets/img/maintenance.svg").default} />
        <div
          style={{
            marginTop: 32,
            marginBottom: 20,
            fontSize: 36,
            fontWeight: 800,
          }}
        >
          Under Maintenance
        </div>
        <div style={{ fontWeight: 500, fontSize: 18 }}>
          We'll be back up and running again shortly.
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenance;
