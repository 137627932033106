import React from "react";
import _ from "lodash";
import { Checkbox } from "@material-ui/core";
import { useTheme } from "@mui/styles";

import checkboxIcon from "assets/img/checkbox.svg";
import checkedboxIcon from "assets/img/checkedbox.svg";

export default function CustomCheckbox(props) {
  const theme = useTheme();

  return (
    <Checkbox
      icon={<i className="meta-crm-icon-ic_checkbox font-size-20" />}
      style={{ color: theme.textColor }}
      checkedIcon={<i className="meta-crm-icon-ic_checkedbox font-size-20" />}
      {...props}
    />
  );
}

CustomCheckbox.defaultProps = {};
