import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  TICKETLIST_FETCH_BEGIN,
  TICKETLIST_FETCH_SUCCESS,
  TICKETLIST_FETCH_FAILURE,
  TICKETLIST_FILTER_SUCCESS,
  TICKETLIST_ORDER_SUCCESS,
  USER_TICKET_LIST_FETCH_BEGIN,
  USER_TICKET_LIST_FETCH_SUCCESS,
  USER_TICKET_LIST_FETCH_FAILURE,
  CLEAN_DATA,
  CREATE_TICKET_SUCCESS,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import { apiUrl } from "features/configure";
const initCounts = { ALL: 0, NEW: 0, ONGOING: 0, DONE: 0 };

const kMapping = {
  Create: "created",
  "Last Message": "lastMessage.created",
  Assignee: "team.name",
  Status: "status",
  "Ticket Title / Issue Type": "title",
};

export function fetchTicketList({ entityName }) {
  return (dispatch, getState) => {
    dispatch({
      type: TICKETLIST_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let ticketList = await axios.get(
          `${apiUrl}api/ticket?entityName=` + entityName
        );

        dispatch({
          type: TICKETLIST_FETCH_SUCCESS,
          data: ticketList.data,
        });
        resolve(ticketList.data);
      } catch (err) {
        dispatch({
          type: TICKETLIST_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function createTicket({
  entityName,
  description,
  title,
  type,
  imagesUrl,
}) {
  return (dispatch, getState) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(apiUrl + "api/ticket/create", {
          type,
          title,
          description,
          entityName,
          imagesUrl,
        });

        dispatch({
          type: CREATE_TICKET_SUCCESS,
          data: {
            ...result.data,
            type,
            lastMessage: { message: description, created: new Date() },
          },
        });
        resolve(result.data);
      } catch (err) {
        reject(err);
      }
    });

    return promise;
  };
}

export function setFilter(newFilter) {
  return (dispatch) => {
    dispatch({
      type: TICKETLIST_FILTER_SUCCESS,
      data: newFilter,
    });
  };
}
export function cleanData() {
  return (dispatch) => {
    dispatch({
      type: CLEAN_DATA,
    });
  };
}

export function setOrder(newOrder, userId) {
  return (dispatch) => {
    dispatch({
      type: TICKETLIST_ORDER_SUCCESS,
      data: newOrder,
      userId,
    });
  };
}

export function fetchUserTicketList({ entityName, userId }) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_TICKET_LIST_FETCH_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let userTickets = await axios.get(
          `${apiUrl}api/ticket/user?userId=${userId}&entityName=` + entityName
        );
        dispatch({
          type: USER_TICKET_LIST_FETCH_SUCCESS,
          data: userTickets.data,
          userId,
        });
      } catch (err) {
        dispatch({
          type: USER_TICKET_LIST_FETCH_FAILURE,
        });
        // console.log(err)
      }
    });

    return promise;
  };
}

export function useFetchTicketList() {
  const dispatch = useDispatch();

  const {
    ticketList,
    fetchTicketListPending,
    fetchTicketListDone,
    ticketCounts,
    filter,
    filterTicketList,
    orderData,
    userTicketList,
    fetchUserTicketListPending,
  } = useSelector(
    (state) => ({
      ticketList: state.metadesk.ticketList,
      filter: state.metadesk.filter,
      filterTicketList: state.metadesk.filterTicketList,
      ticketCounts: state.metadesk.ticketCounts,
      fetchTicketListPending: state.metadesk.fetchTicketListPending,
      fetchTicketListDone: state.metadesk.fetchTicketListDone,
      orderData: state.metadesk.orderData,
      userTicketList: state.metadesk.userTicketList,
      fetchUserTicketListPending: state.metadesk.fetchUserTicketListPending,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchTicketList(data));
    },
    [dispatch]
  );
  const boundFilterAction = useCallback(
    (data) => {
      return dispatch(setFilter(data));
    },
    [dispatch]
  );

  const setOrderAction = useCallback(
    (data, userId) => {
      return dispatch(setOrder(data, userId));
    },
    [dispatch]
  );

  const fetchUserTicketListAction = useCallback(
    (data) => {
      return dispatch(fetchUserTicketList(data));
    },
    [dispatch]
  );

  const cleanDataAction = useCallback(() => {
    return dispatch(cleanData());
  }, [dispatch]);

  const createTicketAction = useCallback(
    (data) => {
      return dispatch(createTicket(data));
    },
    [dispatch]
  );

  return {
    ticketList,
    ticketCounts,
    fetchTicketList: boundAction,
    setFilter: boundFilterAction,
    setOrder: setOrderAction,
    fetchTicketListDone,
    fetchTicketListPending,
    filterTicketList,
    filter,
    orderData,
    userTicketList,
    createTicket: createTicketAction,
    fetchUserTicketList: fetchUserTicketListAction,
    cleanData: cleanDataAction,
    fetchUserTicketListPending,
  };
}
const filterTickets = (filter, orderData, data) => {
  if (filter) {
    if (filter.status !== "ALL") {
      data = _.filter(data, function (o) {
        return _.toUpper(o.status) == filter.status;
      });
    }

    if (filter.issue) {
      data = _.filter(data, function (o) {
        return o.type == filter.issue;
      });
    }
    if (filter.search) {
      data = _.filter(data, function (o) {
        return _.includes(_.toUpper(o.title), _.toUpper(filter.search));
      });
    }
  }

  if (orderData) {
    data = _.orderBy(data, [kMapping[orderData.orderBy]], [orderData.order]);
  }
  return data;
};
export function reducer(state, action) {
  switch (action.type) {
    case TICKETLIST_FETCH_BEGIN:
      return {
        ...state,
        fetchTicketListPending: true,
      };

    case TICKETLIST_FETCH_SUCCESS:
      return {
        ...state,
        ticketList: action.data,
        filterTicketList: filterTickets(state.filter, state.orderData, [
          ...action.data,
        ]),
        fetchTicketListDone: true,
        ticketCounts: action.ticketCounts,
        fetchTicketListPending: false,
      };

    case TICKETLIST_FETCH_FAILURE:
      return {
        ...state,
        fetchTicketListPending: false,
      };
    case TICKETLIST_FILTER_SUCCESS:
      let filter = action.data;
      let data = [...state.ticketList];
      return {
        ...state,
        filter,
        filterTicketList: filterTickets(filter, state.orderData, data),
      };

    case TICKETLIST_ORDER_SUCCESS:
      let orderData = action.data;
      if (action.userId) {
        return {
          ...state,
          orderData,
          userTicketList: {
            ...state.userTicketList,
            [action.userId]: {
              ...state.userTicketList[action.userId],
              tickets: filterTickets(
                "",
                orderData,
                state.userTicketList[action.userId].tickets
              ),
              userId: action.userId,
            },
          },
        };
      }
      return {
        ...state,
        orderData,
        filterTicketList: filterTickets(state.filter, orderData, [
          ...state.ticketList,
        ]),
      };

    case USER_TICKET_LIST_FETCH_BEGIN:
      return {
        ...state,
        fetchUserTicketListPending: true,
      };

    case CREATE_TICKET_SUCCESS:
      const newList = [action.data, ...state.ticketList];
      return {
        ...state,
        ticketList: newList,
        filterTicketList: filterTickets(state.filter, state.orderData, newList),
      };

    case USER_TICKET_LIST_FETCH_SUCCESS:
      return {
        ...state,
        userTicketList: {
          ...state.userTicketList,
          [action.userId]: {
            ...action.data,
            userId: action.userId,
            tickets: filterTickets("", state.orderData, action.data.tickets),
          },
        },
        fetchUserTicketListPending: false,
      };

    case USER_TICKET_LIST_FETCH_FAILURE:
      return {
        ...state,
        fetchUserTicketListPending: false,
      };

    case CLEAN_DATA:
      return {
        ...state,
        ticketList: [],
        filterTicketList: [],
        fetchTicketListDone: false,
        ticketCounts: 0,
      };

    default:
      return state;
  }
}
