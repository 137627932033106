import { createBrowserRouter } from "react-router-dom";

import NotFound from "features/metadesk/components/NotFound";

import { CreateTicket, MessageAdmin } from "features/metadesk";
import { App, Landing } from "features/home";
import NoAuth from "features/metadesk/components/NoAuth";

const envEntity = process.env.REACT_APP_ENTITY;

const isCustomDomain = Boolean(envEntity);
const route = createBrowserRouter([
  {
    path: isCustomDomain ? "/" : "/:entity",
    element: <App />,
    errorElement: isCustomDomain ? <NotFound /> : <NoAuth />,
    children: [
      {
        path: "create",
        element: <CreateTicket />,
      },

      {
        index: true,
        element: <MessageAdmin />,
      },
    ],
  },
]);

export default route;
