const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectDesc: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: 200,
    margin: "5px auto",
  },
  modalPaper: (props) => {
    return {
      // width: "80%",
      width: 485,
      minHeight: 385,
      padding: "48px 38px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
      textAlign: "center",
      backgroundColor: props.background,
      borderRadius: props.borderRadius.radiusNormal,
      border: props.border,
      color: props.textColor,
    };
  },
  active: {
    "& $line": {
      borderColor: "#2B9BFF",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#2B9BFF",
    },
  },
  title: { fontSize: 20, fontWeight: 700 },
  subtitle: { fontSize: 14, fontWeight: 400 },
  button: (props) => {
    return {
      padding: 10,
      borderRadius: props.borderRadius.radiusNormal,
      backgroundColor: props.background,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      boxShadow: "0px 3px 7px rgba(0, 0, 0, 0.1)",
      margin: 4,
      width: "195px",
      height: "115px",

      "&:hover": {
        border: props.border,
      },
    };
  },
  disabled: {
    cursor: "not-allowed",
    border: "2px solid #383538",
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 5,
    background: "#D9D9D9",
    borderRadius: 20,
  },
});

export default styles;
