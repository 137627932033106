import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/styles";
import _, { upperCase } from "lodash";
import moment from "moment";

import TicketType from "./components/TicketType";

import {
  getShortAddress,
  formatDiscordUsername,
  getUserAvatarIcon,
} from "features/helpers/utils";
import Linkify from "linkify-react";
import { useIntl } from "react-intl";

import IconIcDiscord2 from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscord2";

import {
  StyledAddressFrom,
  StyledAdminMessage,
  StyledAdminMessageTime,
  StyledInitUserMessage,
  StyledMacroButton,
  StyledMessageRow,
  StyledMsgAddress,
  StyledMsgField,
  StyledMsgHeader,
  StyledMsgValue,
  StyledUserMessage,
  StyledUserMessageTime,
  StyledRecalledMsg,
  StyledFileBox,
  StyledFileContainer,
} from "./Chat.styles";
import Box from "@mui/material/Box";
import PreviewFile from "@metacrm/metacrm-material-ui/dist/PreviewFile";

const ChatItem = ({
  data,
  index,
  lastOne,
  lastData,
  setOpenImg,
  selectedTicket,
  entityName,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const userId = data.user || data.userId;
  const options = {
    target: (href) => {
      const { hostname } = window.location;
      if (hostname && href.includes(hostname)) {
        return "";
      }
      return "_blank";
    },
    attributes: {
      rel: "noreferrer noopener",
    },
  };
  const address = _.get(selectedTicket, "userWallet.address", "");
  const status = _.get(data, "status", "");

  const renderTime = () => {
    if (!data.created) return <div />;
    return (
      <>
        {moment(data.created).format("YYYY/MM/DD")}
        <br /> {moment(data.created).format("HH:mm")}
      </>
    );
  };

  const renderUserInitTicketMessage = () => {
    return (
      <div
        style={{
          textAlign: "left",
          fontSize: 13,
          padding: 10,
          lineHeight: "22px",
          color: theme.headerColor,
          // border: theme.inputBorder,
          // borderRadius: "10px",
        }}
      >
        <StyledMsgHeader>
          <div>
            <Box display={"inline-block"} fontWeight={700}>
              From:
            </Box>
            <StyledAddressFrom>
              {_.get(selectedTicket, "userWallet.address")}
            </StyledAddressFrom>
          </div>
          <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
            <StyledMsgField theme={theme} my={"5px"}>
              Create:{" "}
              {moment(_.get(selectedTicket, "created")).format(
                "YYYY/MM/DD HH:mm"
              )}
            </StyledMsgField>
            <StyledMsgField theme={theme} my={"5px"}>
              <TicketType ticketType={_.get(selectedTicket, "type")} />
            </StyledMsgField>
          </Box>
        </StyledMsgHeader>
        <Box pt={"10px"} mb="10px" textAlign="right">
          <StyledMsgValue>
            <Linkify options={options}>
              {_.get(selectedTicket, "description")}
            </Linkify>
          </StyledMsgValue>
        </Box>
        <PreviewFile
          files={data.imagesUrl}
          bigImg={true}
          onImgClick={(file) => setOpenImg(file)}
          style={{ lineHeight: "1.1" }}
        />
      </div>
    );
  };
  const renderStatusDisplay = () => {
    if (status == "sending") return "Sending...";
    if (status == "failed")
      return (
        <i
          className="meta-crm-icon-ic_info_fill font-size-16"
          style={{ color: "#FC5555" }}
        />
      );
    return renderTime();
  };

  const renderAdminChatBox = () => {
    const { isRecalled } = data;
    return (
      <div key={index}>
        {getAdminName()}
        <StyledMessageRow style={{ justifyContent: "flex-start" }}>
          <StyledAdminMessage theme={theme} isRecalled={isRecalled}>
            {isRecalled ? (
              <>{formatMessage({ id: "widgetClient.messageState.recall" })}</>
            ) : (
              <>
                {" "}
                <Linkify options={options}>{data.message}</Linkify>
                <PreviewFile
                  files={data.imagesUrl}
                  bigImg={true}
                  onImgClick={(file) => setOpenImg(file)}
                />
              </>
            )}
          </StyledAdminMessage>
          <StyledAdminMessageTime theme={theme}>
            {renderTime()}
          </StyledAdminMessageTime>
        </StyledMessageRow>
      </div>
    );
  };
  const renderUserChatBox = () => {
    return (
      <div key={index}>
        {lastOne !== "user" && (
          <div
            style={{ marginTop: "-5px", marginBottom: 5 }}
            className="endRow"
          >
            <span
              style={{
                color: theme.textColor,
                fontWeight: 500,
                fontSize: 11,
                marginRight: 5,
              }}
            >
              You
            </span>
            <img
              src={require(`assets/img/${
                getUserAvatarIcon(userId, entityName, theme) ||
                "ic_alpacaAllWhite.svg"
              }`)}
              className="smIcon"
            />
          </div>
        )}
        <StyledMessageRow status={status}>
          <StyledUserMessageTime theme={theme}>
            {renderStatusDisplay()}
          </StyledUserMessageTime>
          <StyledInitUserMessage index={index}>
            {index == 0 ? (
              renderUserInitTicketMessage()
            ) : (
              <StyledUserMessage theme={theme} sending={status == "sending"}>
                {data.message && (
                  <Box
                    mb={_.isEmpty(data.imagesUrl) ? 0 : "10px"}
                    textAlign="right"
                  >
                    <Linkify options={options}>{data.message}</Linkify>
                  </Box>
                )}
                <PreviewFile
                  files={data.imagesUrl}
                  bigImg={true}
                  onImgClick={(file) => setOpenImg(file)}
                />
              </StyledUserMessage>
            )}
          </StyledInitUserMessage>
        </StyledMessageRow>
      </div>
    );
  };
  const getAdminName = () => {
    return (
      _.get(data, "team.name", "") &&
      _.get(data, "team.name", "N/A") !==
        _.get(lastData, "team.name", "Admin") && (
        <div style={{ marginBottom: 2 }}>
          <img
            src={require(`assets/img/${
              theme.adminIcon || "ic_alpacaAllWhiteBigFace.svg"
            }`)}
            className="smIcon"
          />
          <span
            style={{
              color: theme.textColor,
              fontWeight: 500,
              fontSize: 11,
            }}
          >
            {_.get(data, "team.name", "")}
          </span>
        </div>
      )
    );
  };
  const getAddress = () => {
    return (
      <StyledMsgAddress className={"startRow"}>
        {address &&
          getShortAddress(_.get(selectedTicket, "userWallet.address", ""))}
      </StyledMsgAddress>
    );
  };

  if (data.isAdminResponse) return renderAdminChatBox();
  return renderUserChatBox();
};

export default ChatItem;
