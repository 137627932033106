import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Notifier } from "features/common";
import LandingHeader from "features/home/components/LandingHeader";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { Outlet, useLocation, useParams } from "react-router-dom";
import NoAuth from "features/metadesk/components/NoAuth.js";
import UnderMaintenance from "features/metadesk/components/UnderMaintenance.js";
import { useConnectWallet } from "./redux/connectWallet.js";
import { useTheme } from "@mui/styles";
import moment from "moment";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function App() {
  const { entity } = useParams();
  const envEntity = process.env.REACT_APP_ENTITY;
  const isCustomDomain = Boolean(envEntity);
  const { auth } = useConnectWallet();
  const theme = useTheme();
  const isUnderMaintain = process.env.REACT_APP_MAINTENANCE === "true";
  const [entityName, setEntityName] = useState(envEntity || entity);
  const { fetchEntityConfig } = useFetchEntityConfig();

  useEffect(() => {
    setEntityName(envEntity || entity);
  }, [entity]);

  useEffect(() => {
    fetchEntityConfig({ entityName });
  }, [entityName]);

  if (isUnderMaintain) {
    return <UnderMaintenance />;
  }
  return (
    <Wrapper>
      <main>
        <LandingHeader
          entityName={entityName}
          openConnect={true}
          isCustomDomain={isCustomDomain}
        />
        <div
          style={{
            paddingTop: 60,
            background: theme.backgroundBaseColor,
            backgroundSize: "cover",
            minHeight: "calc(100vh - 30px)",
          }}
        >
          {auth ? (
            <Outlet context={[entityName, isCustomDomain]} />
          ) : (
            <NoAuth />
          )}
          <Notifier />

          <div
            className="betweenRow"
            style={{
              padding: "4px 7px 7px 7px",
              color: "#d6d6d6",
              fontSize: 12,
            }}
          >
            <div></div>
            <a target="_blank" href="https://metacrm.inc/#/" rel="noreferrer">
              <img src={require("assets/img/poweredBy.svg").default} />
            </a>
            <div>
              {process.env.REACT_APP_VERSION} (
              {moment(process.env.REACT_APP_DEPLOYED_AT).format("YYYY.MM.DD")})
            </div>
          </div>
        </div>
      </main>
    </Wrapper>
  );
}
