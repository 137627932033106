export const TICKET_TYPE_FETCH_BEGIN = "TICKET_TYPE_FETCH_BEGIN";
export const TICKET_TYPE_FETCH_SUCCESS = "TICKET_TYPE_FETCH_SUCCESS";
export const TICKET_TYPE_FETCH_FAILURE = "TICKET_TYPE_FETCH_FAILURE";

export const TICKETLIST_FETCH_BEGIN = "TICKETLIST_FETCH_BEGIN";
export const TICKETLIST_FETCH_SUCCESS = "TICKETLIST_FETCH_SUCCESS";
export const TICKETLIST_FETCH_FAILURE = "TICKETLIST_FETCH_FAILURE";
export const TICKETLIST_FILTER_SUCCESS = "TICKETLIST_FILTER_SUCCESS";
export const TICKETLIST_ORDER_SUCCESS = "TICKETLIST_ORDER_SUCCESS";

export const USER_LIST_FETCH_BEGIN = "USER_LIST_FETCH_BEGIN";
export const USER_LIST_FETCH_SUCCESS = "USER_LIST_FETCH_SUCCESS";
export const USER_LIST_FETCH_FAILURE = "USER_LIST_FETCH_FAILURE";

export const USER_TICKET_LIST_FETCH_BEGIN = "USER_TICKET_LIST_FETCH_BEGIN";
export const USER_TICKET_LIST_FETCH_SUCCESS = "USER_TICKET_LIST_FETCH_SUCCESS";
export const USER_TICKET_LIST_FETCH_FAILURE = "USER_TICKET_LIST_FETCH_FAILURE";

export const CLEAN_DATA = "CLEAN_DATA";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";

export const ENTITY_CONFIG_FETCH_SUCCESS = "ENTITY_CONFIG_FETCH_SUCCESS";

export const FETCH_THEME_SUCCESS = "FETCH_THEME_SUCCESS";
