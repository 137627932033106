import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";

import { AppBar, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { ThemeConsumer } from "styled-components";

const AntTabs = withStyles({
  root: () => ({ minHeight: 20 }),
  indicator: () => ({
    background: "transparent",
  }),
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: (props) => {
    return {
      textTransform: "none",
      minWidth: 70,
      fontWeight: 500,
      marginRight: 5,
      padding: 3,
      minHeight: 28,
      backgroundColor: props.customerTheme.statusBackground,
      boxShadow: props.customerTheme.boxShadow,
      borderRadius: props.customerTheme.borderRadius.radiusNormal,
      opacity: 1,
      lineHeight: 1,

      "&:hover": {
        color: "#555555",
        opacity: 1,
      },
      "&.Mui-selected": {
        backgroundColor: props.customerTheme.selectedTabBackground,
        // boxShadow: "0px 0px 0px #000000",

        "& .MuiTab-wrapper": {
          fontWeight: 700,
          color: props.customerTheme.selectedTabColor,
        },
      },
      "&:focus": {
        color: props.customerTheme.selectedTabColor,
      },
    };
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ overflow: "hidden", padding: 5 }}>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",

    marginLeft: "auto",
    marginRight: "auto",
  },
  tabs: {
    color: "#383538",
    overflow: "hidden",
    backgroundColor: "transparent",
  },
  tab: (props) => {
    return {
      fontSize: 12,
      margin: 3,
      textTransform: "capitalize",
      border: props.border,
      "& .MuiTab-wrapper": {
        fontWeight: 700,
        color: props.tableFontColor,
      },
    };
  },
}));

export default function CustomTabs({
  tabs,
  initIndex = 0,
  tabIndex,
  handleChange,
  style,
  sm,
}) {
  const [value, setValue] = useState(parseInt(initIndex));
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles(theme);

  const renderTabs = () => {
    return (
      <div position="static" className={classes.tabs}>
        <AntTabs
          // TabIndicatorProps={{ style: { background: "transparent" } }}
          value={typeof tabIndex === "number" ? tabIndex : value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
        >
          {tabs.map((tab, index) => {
            // let color =
            //   tabIndex == index ? theme.palette.background.primary : "";
            return (
              <AntTab
                key={tab.label}
                style={{ ...style }}
                className={`${classes.tab}`}
                label={tab.label}
                disabled={tab.disabled}
                customerTheme={theme}
                {...a11yProps(index)}
              />
            );
          })}
        </AntTabs>{" "}
      </div>
    );
  };

  return (
    <div>
      {renderTabs()}

      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            value={typeof tabIndex === "number" ? tabIndex : value}
            index={index}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
}
