import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTheme } from "@mui/styles";
import { Select, MenuItem, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ customerTheme, sm }) => ({
  "& svg": {
    right: "-5px",
    fill: customerTheme.textColor,
  },

  "& .MuiInputBase-input": {
    borderRadius: customerTheme.borderRadius,
    border: customerTheme.border,
    textAlign: "left",
    position: "relative",
    background: customerTheme.background,
    fontFamily: "Inter",
    color: customerTheme.textColor,
    margin: "0",
    fontSize: 15,
    fontWeight: 500,
    padding: "4px 10px",
    boxShadow: customerTheme.boxShadow,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "&:focus": {
      borderRadius: customerTheme.borderRadius,
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ customerTheme }) => ({
  fontSize: 13,
  fontWeight: customerTheme.fontWeight.MEDIUM,

  "&.Mui-selected": {
    backgroundColor: customerTheme.selectedTabBackground,
    color: customerTheme.selectedTabColor,

    ":hover": {
      backgroundColor: customerTheme.selectedTabBackground,
    },
    ":focus-visible": {
      backgroundColor: customerTheme.selectedTabBackground,
    },
  },
  "&:hover": {
    backgroundColor: customerTheme.selectedTabBackground,
    color: customerTheme.selectedTabColor,
  },
}));

export const CustomDropdown = ({
  data,
  onSelect,
  value,
  style,
  sm,
  fullWidth,
  disabled,
  placeholder,
  render,
}) => {
  const theme = useTheme();

  return (
    <Select
      disabled={disabled}
      value={value}
      variant="outlined"
      MenuProps={{
        disableScrollLock: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        MenuListProps: { disablePadding: true },
      }}
      style={{ ...style }}
      input={
        <BootstrapInput customerTheme={theme} sm={sm} fullWidth={fullWidth} />
      }
      onChange={onSelect}
      displayEmpty="true"
      renderValue={(selected) => {
        if (!selected || selected.length === 0) {
          return <div>{placeholder ? placeholder : ""}</div>;
        }
        let match = _.find(data, ["value", selected]);
        return render ? render(match) : _.get(match, "label", "");
      }}
    >
      {data.map((d) => (
        <StyledMenuItem
          key={d.value}
          value={d.value}
          disabled={d.disabled}
          customerTheme={theme}
        >
          {render ? render(d) : d.label}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

export default CustomDropdown;
