import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({}));

const Channel = ({ ticket }) => {
  const classes = useStyles();
  if (!ticket) return <span />;
  return (
    <div
      className="startRow"
      style={{
        padding: "1px 5px",
        color: "#555555",
        background: "#E6E6E6",
        borderRadius: 2,
        boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)",
        width: 48,
        marginLeft: 5,
      }}
    >
      via{" "}
      {ticket.from == "discord" ? (
        <img
          src={require("assets/img/colorDiscord.svg").default}
          style={{ width: 15, height: 15, marginLeft: 5 }}
        />
      ) : (
        <img
          src={require("assets/img/web.svg").default}
          style={{ width: 15, height: 15, marginLeft: 5 }}
        />
      )}
    </div>
  );
};

export default Channel;
