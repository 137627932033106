import React, { useState, useEffect } from "react";
// import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import { CssBaseline } from "@material-ui/core";
import _ from "lodash";
import { defaultThemeConfig } from "features/configure";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import createAppTheme from "./createAppTheme.utils";
import { useSelector } from "react-redux";
import { selectCustomColor } from "features/metadesk/redux/entityConfig.selector";
import ColorModeContext from "@metacrm/metacrm-material-ui/dist/contexts/ColorMode.context";
import useColorMode from "@metacrm/metacrm-material-ui/dist/hooks/useColorMode.hooks";

export const ThemeContext = React.createContext((themeName) => {});

const CustomThemeProvider = (props) => {
  const { colorModeHooksValue, theme: mainTheme } = useColorMode();
  const { mode } = colorModeHooksValue;
  const [entityName, setEntityName] = useState("");
  const customColor = useSelector(selectCustomColor);

  const defaultTheme = defaultThemeConfig.theme;
  const themeFromApi = customColor
    ? createAppTheme(customColor)
    : defaultTheme(mode);

  const [theme, setTheme] = useState(
    Object.assign({}, themeFromApi, mainTheme)
  );

  useEffect(() => {
    const entityTheme = defaultThemeConfig.theme;
    let themeMode = mode;

    const resultTheme = Object.assign(
      {},
      customColor ? createAppTheme(customColor) : entityTheme(themeMode),
      mainTheme
    );
    setTheme(resultTheme);
  }, [mainTheme, mode, customColor]);

  const setThemeName = (themeName) => {
    setEntityName(themeName);
  };

  return (
    <ColorModeContext.Provider value={colorModeHooksValue}>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </ColorModeContext.Provider>
  );
};

export default CustomThemeProvider;
