import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SELECT_USER } from "./constants";
import { useConnectWallet } from "features/home/redux/hooks";
export const userSelect = (type, user, signout) => {
  // if (user && type !== user) signout();
  return {
    type: SELECT_USER,
    user: type,
  };
};

export function useUserSelect() {
  const dispatch = useDispatch();
  const { user } = useSelector(
    (state) => ({
      user: state.home.user,
    }),
    shallowEqual
  );
  const { signout } = useConnectWallet();
  const enqueueAction = useCallback(
    (type) => dispatch(userSelect(type, user, signout)),
    [dispatch]
  );

  return {
    user,
    userSelect: enqueueAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SELECT_USER:
      return {
        ...state,
        user: action.user,
      };

    default:
      return state;
  }
}
