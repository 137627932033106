import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";
import { Modal, Grid } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import Button from "components/CustomButtons/Button";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useConnectWallet, useUserSelect } from "features/home/redux/hooks";
import styles from "./styles";
import { SiweMessage } from "siwe";
import { apiUrl, defaultThemeConfig } from "features/configure";
import axios from "axios";
import { useFetchEntityConfig } from "features/metadesk/redux/hooks";
import { useNavigate } from "react-router-dom";
import title from "assets/img/MetaMask.png";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from "wagmi";
import {
  MartianWalletName,
  AptosWalletName,
  RiseWalletName,
  PontemWalletName,
  useWallet,
} from "@manahippo/aptos-wallet-adapter";
import _ from "lodash";
import ReactGA from "react-ga4";
import {
  generateKey,
  encryptToken,
  decryptToken,
} from "features/helpers/encrypt";
import MemberMenu from "./MemberMenu";
import { parseCustomDomainUrl } from "features/helpers/utils";
import Box from "@mui/material/Box";
import { StyledTabLabelImg, StyledBuildWith } from "./ConnectWallet.styles";
import { useIntl } from "react-intl";
import { selectAptosConnectorImg } from "features/metadesk/redux/entityConfig.selector";
import { useSelector } from "react-redux";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import { SolflareWalletName } from "@solana/wallet-adapter-solflare";
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import config from "../../config";

axios.defaults.withCredentials = true;
const useStyles = makeStyles(styles);

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

const ConnectWallet = ({
  chooseUser,
  buttonImg,
  hidden = false,
  defaultOpen = false,
  isCustomDomain,
  entityName,
  startAction,
}) => {
  const [shortAddress, setShortAddress] = useState("");
  const { chain } = useNetwork();
  const {
    authCheck,
    auth,
    userData,
    authCheckPending,
    logoutLoading,
    authCheckDone,
    signout,
    getAuthenticatedInfo,
    getAuthenticatedInfoPending,
    getAuthenticatedInfoDone,
  } = useConnectWallet();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles(theme);
  const { disconnect } = useDisconnect();
  const { fetchEntityConfig, entityConfig } = useFetchEntityConfig();
  const companyLogo = _.get(entityConfig, "company.logo");
  const ecoSystemData = _.get(entityConfig, "ecosystem");
  const [anchorMenu, setAnchorMenu] = useState(null);
  const aptosWallets = [
    PontemWalletName,
    MartianWalletName,
    RiseWalletName,
    AptosWalletName,
  ];
  const solanaWallets = [PhantomWalletName, SolflareWalletName];

  const aptosConnectorImg = useSelector(selectAptosConnectorImg);

  const solanaConnectorImg = {
    [PhantomWalletName]: require("assets/img/phantom.png"),
    [SolflareWalletName]: require("assets/img/solflare.png"),
  };
  const { formatMessage } = useIntl();

  const handleOpenMemberMenu = (e) => {
    setAnchorMenu(e.currentTarget);
  };
  const handleCloseMemberClose = (e) => {
    setAnchorMenu(null);
  };

  const setupAxiosAuthHeader = async () => {
    const encryptedToken = localStorage.getItem("access_token");

    if (encryptedToken) {
      try {
        let key = generateKey(address);
        let decryptedToken = decryptToken(encryptedToken, key);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${decryptedToken}`;
      } catch (error) {
        delete axios.defaults.headers.common["Authorization"];
      }
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  const buttonText = {
    user: "Take a form",
    creator: "Create a form",
  };

  const {
    connect: aptosConnect,
    autoConnect,
    disconnect: aptosDisconnect,
    connecting: aptosConnecting,
    connected: aptosConnected,
    account: aptosAccount,
    signMessage: aptosSignMessage,
  } = useWallet();

  const {
    select: solanaSelect,
    connect: solanaConnect,
    disconnect: solanaDisconnect,
    connecting: solanaConnecting,
    connected: solanaConnected,
    publicKey: solanaAccount,
    signMessage: solanaSignMessage,
  } = useSolanaWallet();

  const {
    address: evmAddress,
    isConnected: evmConnected,
    isConnecting: evmConnecting,
    connector: activeConnector,
  } = useAccount({
    onConnect() {
      if (!computer) setRequestAuth(false);
    },
    onDisconnect() {
      setRequestAuth(false);
    },
  });

  const address = (() => {
    if (ecoSystemData === "aptos") {
      return aptosAccount?.address;
    } else if (ecoSystemData === "evm") {
      return evmAddress;
    } else if (ecoSystemData === "solana") {
      const test = solanaAccount?.toBase58();
      return test;
    } else {
      return evmAddress;
    }
  })();

  const isConnected = (() => {
    if (ecoSystemData === "aptos") {
      return aptosConnected;
    } else if (ecoSystemData === "evm") {
      return evmConnected;
    } else if (ecoSystemData === "solana") {
      return solanaConnected;
    } else {
      return evmConnected;
    }
  })();
  const isConnecting = (() => {
    if (ecoSystemData === "aptos") {
      return aptosConnecting;
    } else if (ecoSystemData === "evm") {
      return evmConnecting;
    } else if (ecoSystemData === "solana") {
      return solanaConnecting;
    } else {
      return evmConnecting;
    }
  })();

  const { signMessageAsync } = useSignMessage();
  const { connect, connectors } = useConnect({
    onError(error) {
      // console.log("Error", error);
      setRequestAuth(false);
    },
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [requestAuth, setRequestAuth] = useState(false);
  const domain = window.location.host;
  const origin = window.location.origin;
  const [currentConnectors, setCurrentConnectors] = useState([]);
  const [onlyWalletConnect, setOnlyWalletConnect] = useState(false);

  const renderAddress = () => {
    if (!address) return "";
    return `${address.slice(0, 6)}......${address.slice(-4)}`;
  };

  const intervalId = useRef(null);

  useEffect(() => {
    async function fetchData() {
      if (address && isConnected) {
        await setupAxiosAuthHeader();
        authCheck(entityName);

        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        intervalId.current = setInterval(() => {
          authCheck(entityName);
        }, 15000);
      }
    }

    fetchData();

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [isConnected, address]);

  useDebounceEffect(
    () => {
      if (!authCheckPending && !auth && defaultOpen) {
        setOpen(true);
      }
      if (!authCheckPending && authCheckDone && auth) {
        getAuthenticatedInfo(entityName);
      }
    },
    500,
    [authCheckPending, authCheckDone, auth]
  );

  useEffect(async () => {
    if (!getAuthenticatedInfoPending && getAuthenticatedInfoDone && userData) {
      const addrs = _.get(userData, "address", "");
      if (addrs.length < 11) {
        setShortAddress(addrs);
      } else {
        setShortAddress(renderAddress());
      }
    }
  }, [userData]);

  useEffect(() => {
    if (open && auth && isConnected && address) {
      setOpen(false);
      setRequestAuth(false);
      navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/"));
    }
  }, [address, isConnected, auth]);

  useEffect(() => {
    let filteredConnectors = connectors;

    if (!computer) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name !== "MetaMask";
      });
      //mobile version (only show injected metamask)
    }
    if (!window.ethereum) {
      filteredConnectors = _.filter(filteredConnectors, function (connector) {
        return connector.name == "WalletConnect";
      });
      setOnlyWalletConnect(true);
    }
    setCurrentConnectors(filteredConnectors);
  }, [computer, connectors, window]);

  const connectorsImg = {
    metaMask: require("assets/img/MetaMask.png"),
    injected: require("assets/img/BrowserWallet.png"),
    walletConnect: require("assets/img/WalletConnect.svg").default,
  };

  const signoutAndRedirect = async () => {
    handleCloseMemberClose();
    try {
      signout();
      navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/"));
    } catch (err) {
      // console.log(err);
    }
  };

  const signIn = async () => {
    try {
      setRequestAuth(true);
      let res;
      if (entityConfig.signatureSignInDisabled) {
        res = await axios.post(
          `${apiUrl}api/session/sign-in/address`,
          { address: address, entityName },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (res.data && res.status == 200) {
          ReactGA.event({
            category: "Status",
            action: "Login Success",
            label: "signInWithAddress",
          });
        }
      } else {
        const nonceRes = await axios.get(`${apiUrl}api/session/nonce`);
        const nonce = nonceRes.data;

        if (ecoSystemData === "aptos") {
          await aptosSignMessage({
            address: address,
            message: `Welcome to ${domain}! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 1 hr.`,
            nonce,
          });

          res = await axios.post(
            `${apiUrl}api/session/sign-in/address`,
            { address: address, entityName },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (ecoSystemData === "solana") {
          const message = new TextEncoder().encode(
            `Welcome to ${domain}! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 1 hr.`
          );
          await solanaSignMessage(message);

          res = await axios.post(
            `${apiUrl}api/session/sign-in/address`,
            { address: address, entityName },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (ecoSystemData === "evm") {
          const message = await createSiweMessage(
            address,
            `Welcome to ${domain}! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 1 day.`,
            nonce
          );
          const signature = await signMessageAsync({ message });

          res = await axios.post(
            `${apiUrl}api/session/sign-in`,
            JSON.stringify({ message, signature, entityName, nonce }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (res.data && res.status == 200) {
          ReactGA.event({
            category: "Status",
            action: "Login Success",
            label: "signInWithEthereum",
          });
        }
      }

      let key = generateKey(address);
      let accessToken = res.data.accessToken;
      let encryptedAccessToken = encryptToken(accessToken, key);
      localStorage.setItem("access_token", encryptedAccessToken);

      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      authCheck(entityName);
      setRequestAuth(false);
    } catch (err) {
      console.error(err);
      ReactGA.event({
        category: "Status",
        action: "Login Fail",
        label: _.get(err, "message", "").includes("user rejected signing")
          ? "user rejected"
          : _.get(err, "message", ""),
      });
      setRequestAuth(false);
    }
  };
  const createSiweMessage = async (address, statement, nonce) => {
    const numOfHours = config.sessionNumOfHours;
    var expirationTime = new Date();
    expirationTime.setTime(
      expirationTime.getTime() + numOfHours * 60 * 60 * 1000
    );

    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: chain.id,
      nonce: nonce,
      expirationTime: expirationTime.toISOString(),
    });
    return message.prepareMessage();
  };

  const renderConnecting = () => {
    return (
      <div>
        <div style={{ marginBottom: "45px" }}>
          <img
            src={title}
            style={{
              height: 60,
              objectFit: "contain",
              verticalAlign: "middle",
            }}
          />
          <div style={{ color: theme.textColor, fontWeight: 500 }}>
            MetaMask
          </div>
        </div>
        <div>
          <LoadingComponent loadingText="Connecting..." />
        </div>
      </div>
    );
  };
  const renderLoginPage = () => {
    return (
      <div style={{ textAlign: "center", padding: 0 }}>
        <div className="row">
          <div style={{ fontWeight: 700, fontSize: computer ? 16 : 12 }}>
            Welcome to{" "}
          </div>
          {entityConfig.company && (
            <img
              src={companyLogo || defaultThemeConfig.headerLogo}
              style={{
                height: computer ? 25 : 15,
                marginLeft: 10,
              }}
            />
          )}
          <div
            style={{
              fontWeight: 700,
              fontSize: computer ? 16 : 12,
              marginLeft: 5,
            }}
          >
            {entityName == "curve" ? "" : "Support Center"}
          </div>
        </div>
        {requestAuth ? (
          <div>
            <div
              style={{ margin: "15px 0", fontSize: 14 }}
              className="startRow"
            >
              Connected Address :{" "}
              <span style={{ fontWeight: 600, fontSize: 23 }}>
                {renderAddress()}
              </span>
            </div>

            <div style={{ marginTop: "85px" }}>
              <LoadingComponent loadingText="Signing in..." />
            </div>
          </div>
        ) : (
          <div>
            <div style={{ margin: "15px 0 45px" }} className="startRow">
              Connected Address :{" "}
              <span style={{ fontWeight: 600, fontSize: 23 }}>
                {renderAddress()}
              </span>
            </div>

            {auth ? (
              ""
            ) : (
              <div
                style={{ marginBottom: 4, fontWeight: 400, fontSize: "17px" }}
              >
                Please sign in to continue
              </div>
            )}
            <Button
              color="primary"
              style={{ marginTop: "10px", width: 360 }}
              onClick={async () => {
                ReactGA.event({
                  category: "Click",
                  action: "Sign in",
                  label: "normallogin",
                });
                await signIn();
              }}
            >
              SIGN IN{" "}
              <i
                className="meta-crm-icon-ic_login font-size-20"
                style={{ marginLeft: 10 }}
              />
            </Button>
            <div>
              <Button
                color="text"
                style={{
                  marginTop: 10,
                }}
                onClick={() => {
                  ReactGA.event({
                    category: "Click",
                    action: "Try Other Wallet",
                    label: "normallogin",
                  });

                  disconnect();
                  aptosDisconnect();
                  solanaDisconnect();
                  setRequestAuth(false);
                }}
              >
                Try other wallet
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderChooseWallet = () => {
    let walletContent;

    if (onlyWalletConnect) {
      walletContent = renderWalletOnlyWalletConnectStatus();
    } else {
      walletContent = renderWalletLoginWays();
    }
    return (
      <>
        <div className={classes.title}>Choose your wallet</div>
        <div
          className={classes.subtitle}
          style={{ margin: "18px auto", maxWidth: 308 }}
        >
          Connect your wallet for seamless Web3 interaction with no password
          required.
        </div>
        <div>
          {walletContent}
          {window.ethereum ? (
            <div
              style={{
                textAlign: "center",
                color: theme.textColor,
                fontWeight: 400,
                fontSize: 14,
                marginTop: 32,
              }}
            >
              Connecting wallet is only for our team to identify your wallet
              address, ensuring personalized customer service tailored to your
              needs. We sincerely value your trust. Please refer to this{" "}
              <a
                href="https://metacrm.inc/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                link
              </a>{" "}
              for more information.
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const renderModalContent = () => {
    if (isConnected) {
      // 第二步驟 連結錢包後
      return renderLoginPage(false);
    } else if (isConnecting) {
      // 選定某個錢包後 跳出視窗 還沒按連結的等待畫面
      return renderConnecting();
    } else {
      return <div>{renderChooseWallet()}</div>;
    }
  };

  const renderAptosWalletLoginWays = () => (
    <Box mt="20px">
      <Grid container>
        {aptosWallets.map((connector, i) => {
          return (
            <Grid item xs={12} sm={6} key={i}>
              <div
                className={`${classes.button} `}
                key={connector.id}
                onClick={async () => {
                  try {
                    ReactGA.event({
                      category: "Click",
                      action: "Select Wallet",
                      label: connector.id,
                    });
                    await aptosConnect(connector);
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <div>
                  <img
                    src={aptosConnectorImg[connector]}
                    style={{ width: 57, height: 57 }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 5,
                  }}
                >
                  {connector}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <StyledBuildWith>
        {formatMessage({ id: "login.wallet.builtWith" })}
        <StyledTabLabelImg
          component="img"
          src={require("assets/img/aptos.png")}
          sx={{ marginLeft: "6px" }}
        ></StyledTabLabelImg>{" "}
        {formatMessage({ id: "global.blockchain.aptos" })}
      </StyledBuildWith>
    </Box>
  );

  const renderSolanaLoginWays = () => (
    <Box mt="20px">
      <Grid container>
        {solanaWallets.map((connector, i) => {
          return (
            <Grid item xs={12} sm={6} key={i}>
              <div
                className={`${classes.button} `}
                key={connector.id}
                onClick={async () => {
                  try {
                    ReactGA.event({
                      category: "Click",
                      action: "Select Wallet",
                      label: connector.id,
                    });
                    await solanaSelect(connector);
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                <div>
                  <img
                    src={solanaConnectorImg[connector]}
                    style={{ width: 57, height: 57 }}
                  />
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 5,
                  }}
                >
                  {connector}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

      <StyledBuildWith>
        {formatMessage({ id: "login.wallet.builtWith" })}
        <StyledTabLabelImg
          component="img"
          src={require("assets/img/solana.png")}
          sx={{ marginLeft: "6px" }}
        ></StyledTabLabelImg>{" "}
        {formatMessage({ id: "global.blockchain.solana" })}
      </StyledBuildWith>
    </Box>
  );

  const renderEvmWalletLoginWays = () => (
    <Box mt="20px">
      <Grid container>
        {currentConnectors.map((connector, i) => {
          return (
            <Grid item xs={6} key={i}>
              <div
                className={`${classes.button} `}
                disabled={!connector.ready}
                key={connector.id}
                onClick={async () => {
                  ReactGA.event({
                    category: "Click",
                    action: "Select Wallet",
                    label: connector.id,
                  });
                  connect({ connector });
                }}
              >
                <div>
                  <img src={connectorsImg[connector.id]} className="lgIcon" />
                </div>
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    marginTop: 5,
                  }}
                >
                  {connector.name}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

  const renderWalletOnlyWalletConnectStatus = () => {
    console.log("ecoSystemData: ", ecoSystemData);

    if (ecoSystemData === "aptos") {
      return renderAptosWalletLoginWays();
    } else {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={!currentConnectors[0].ready}
              key={currentConnectors[0].name}
              onClick={async () => {
                let connector = currentConnectors[0];
                ReactGA.event({
                  category: "Click",
                  action: "Connect Wallet (Mobile)",
                  label: connector.name,
                });
                connect({ connector });
              }}
            >
              Click to connect
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const renderWalletLoginWays = () => {
    if (ecoSystemData === "evm") {
      return renderEvmWalletLoginWays();
    } else if (ecoSystemData === "aptos") {
      return renderAptosWalletLoginWays();
    } else if (ecoSystemData === "solana") {
      return renderSolanaLoginWays();
    }
  };

  const renderLogoutModal = () => {
    return (
      <Modal open={logoutLoading} className={classes.modal}>
        <div className={classes.modalPaper} style={{ flexDirection: "column" }}>
          <LoadingComponent loadingText="Sign out..." />
        </div>
      </Modal>
    );
  };

  const renderModal = (canClose = true) => {
    return (
      <Modal
        open={open}
        onClose={() => {
          if (canClose) {
            setRequestAuth(false);
            setOpen(false);
          }
        }}
        className={classes.modal}
      >
        <div className={classes.modalPaper}>{renderModalContent()}</div>
      </Modal>
    );
  };
  const chooseUserLogin = async (u) => {
    if (auth) {
      navigate(parseCustomDomainUrl(isCustomDomain, entityName, "/"));
    } else {
      disconnect();
      setOpen(true);
    }
  };
  if (hidden) {
    return <>{renderModal(true)}</>;
  }
  if (!entityName) return;
  if (chooseUser) {
    return (
      <>
        {buttonImg ? (
          <img
            src={buttonImg}
            onClick={() => chooseUserLogin(chooseUser)}
            style={{
              height: 50,
              cursor: "pointer",
            }}
          />
        ) : (
          <Button
            color="form"
            style={{
              backgroundColor: "white",
              margin: 20,
              border: "1px solid #E6E6E6",
              borderRadius: 40,
              color: "#383538",
              width: 220,
              height: 44,
            }}
            onClick={() => chooseUserLogin(chooseUser)}
          >
            {buttonText[chooseUser]}
          </Button>
        )}

        {renderModal()}
      </>
    );
  }
  return (
    <>
      {renderLogoutModal()}
      <div>
        {auth ? (
          <>
            <Button
              color="secondary"
              style={{ margin: 0, height: "32px", paddingRight: "15px" }}
              onClick={handleOpenMemberMenu}
            >
              <div className={classes.icon} />
              <span
                style={{
                  marginRight: "45px",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                {shortAddress}{" "}
              </span>
              <i className="meta-crm-icon-ic_menu font-size-14" />
            </Button>
            <MemberMenu
              anchorMenu={anchorMenu}
              onCloseMemberClose={handleCloseMemberClose}
              onSignoutAndRedirect={signoutAndRedirect}
            />
          </>
        ) : (
          <Button
            color="secondary"
            style={{ marginLeft: 15 }}
            onClick={() => {
              let currentLocation = window.location.pathname;
              if (currentLocation.indexOf("login") >= 0) {
                chooseUserLogin(currentLocation.split("/")[1]);
              } else {
                chooseUserLogin(`/${entityName}`);
              }
            }}
          >
            Sign in | Connect
          </Button>
        )}
        {renderModal()}
      </div>
    </>
  );
};

export default ConnectWallet;
