import { createSelector } from "reselect";
import {
  MartianWalletName,
  AptosWalletName,
  RiseWalletName,
  PontemWalletName,
  useWallet,
} from "@manahippo/aptos-wallet-adapter";

const connectorsImg = {
  metaMask: require("assets/img/MetaMask.png"),
  injected: require("assets/img/BrowserWallet.png"),
  walletConnect: require("assets/img/WalletConnect.svg").default,
};

const aptosConnectorImg = {
  [PontemWalletName]: require("assets/img/pontem.svg").default,
  [MartianWalletName]: require("assets/img/martian.png"),
  [RiseWalletName]: require("assets/img/rise.png"),
  [AptosWalletName]: require("assets/img/petra.png"),
};

const selectEntityConfigReducer = (state) => {
  return state.metadesk.entityConfig;
};

const selectThemeReducer = (state) => {
  return state.metadesk.theme;
};

export const selectConnectorsImg = (state) =>
  createSelector([], () => connectorsImg);

export const selectAptosConnectorImg = createSelector(
  [],
  () => aptosConnectorImg
);

export const selectSurveyConfig = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) =>
    entityConfig.surveyConfig || {
      isSurveyEnabled: false,
      surveyBtnStyle: "star",
      surveyMsg: "",
    }
);

export const selectTicketTypeForDropdown = createSelector(
  [selectEntityConfigReducer],
  (entityConfig) =>
    entityConfig.ticketTypes.map((ticketType) => {
      return { ...ticketType, value: ticketType.name, label: ticketType.name };
    })
);

export const selectCustomColor = createSelector(
  [selectThemeReducer],
  (entityTheme) => {
    if (entityTheme.name === "dark") {
      return {
        backgroundColor: "#1C1C1C",
        textColor: "#FFFFFF",
        borderColor: "#D6D6D6",
        hoverColor: "#383538",
        buttonBackgroundColor: "#0096FF",
        buttonTextColor: "#000000",
        backgroundBaseColor: "#777777",
      };
    } else if (entityTheme.name === "custom") {
      return {
        backgroundColor: entityTheme.customColor.primary,
        textColor: entityTheme.customColor.text,
        borderColor: entityTheme.customColor.border,
        hoverColor: entityTheme.customColor.hover,
        buttonBackgroundColor: entityTheme.customColor.button,
        buttonTextColor: entityTheme.customColor.buttonText,
        backgroundBaseColor: entityTheme.customColor.background,
      };
    } else {
      //light
      return {
        backgroundColor: "#ffffff",
        textColor: "#383538",
        borderColor: "#D6D6D6",
        hoverColor: "#CBE6FF",
        buttonBackgroundColor: "#0096FF",
        buttonTextColor: "#F8F8F8",
        backgroundBaseColor: "#ffffff",
      };
    }
  }
);
