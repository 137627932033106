import React from "react";
// import { makeStyles, useTheme } from "@mui/styles";
import { makeStyles, useTheme } from "@mui/styles";
import _ from "lodash";
import { useFetchEntityConfig } from "../redux/hooks";
import { useSelector } from "react-redux";
import { selectTicketTypeForDropdown } from "features/metadesk/redux/entityConfig.selector";

const useStyles = makeStyles((theme) => ({
  inboxTicketType: {
    // height: "23px",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "18px",
    textTransform: "uppercase",

    padding: "2px 10px",
    borderRadius: "20px",
    marginLeft: 0,
  },
}));

const TicketType = ({ ticketType, noBorder, style: styleFroProp }) => {
  const classes = useStyles();
  const theme = useTheme();
  const ticketTypeForDropdown = useSelector(selectTicketTypeForDropdown);

  if (!ticketType) return <span />;
  let index = _.findIndex(ticketTypeForDropdown, { value: ticketType });
  let background = "#9F6FEE";

  let colors = theme.ticketTypeBackground;
  background = colors[index % colors.length];
  let color = theme.ticketTypeColor;
  let fontWeight = theme.fontWeight;
  if (ticketType == "All") {
    background = "#ffffff";
    fontWeight = 500;
    color = theme.tableFontColor;
  }
  let style = {
    minWidth: 150,
    textAlign: "center",
    display: "inline-block",
    whiteSpace: "nowrap",
    ...styleFroProp,
  };

  return (
    <span
      className={classes.inboxTicketType}
      style={{ border: noBorder ? 0 : theme.border, ...style }}
    >
      {_.truncate(ticketType, {
        length: 20,
      })}
    </span>
  );
};

export default TicketType;
