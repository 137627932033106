import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import SocialIcons from "./SocialIcons";

const MenuItems = ({ openConnect, entityName, isCustomDomain }) => {
  return (
    <div>
      {entityName ? (
        <ConnectWallet
          defaultOpen={openConnect}
          entityName={entityName}
          isCustomDomain={isCustomDomain}
        />
      ) : (
        <SocialIcons />
      )}
    </div>
  );
};

export default MenuItems;
