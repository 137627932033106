import React, { useState, useEffect, useRef } from "react";
import { Grid, IconButton, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";
import _, { set } from "lodash";
import { apiUrl } from "features/configure";
import moment from "moment";
import axios from "axios";
import { useConnectWallet } from "features/home/redux/connectWallet";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import Button from "components/CustomButtons/Button";
import TicketType from "./components/TicketType";
import TicketStatus from "./components/TicketStatus";
import { Loading } from "features/common";
import ChatItem from "./ChatItem";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "features/common/redux/snackbar";
import { getShortAddress } from "features/helpers/utils";
import { selectSurveyConfig } from "./redux/entityConfig.selector";
import { useSelector } from "react-redux";
import SurveyMsg from "./SurveyMsg";
import { Box } from "@mui/material";
import { IconFileUploader } from "@metacrm/metacrm-material-ui/dist/FileUploader";
import PreviewFile from "@metacrm/metacrm-material-ui/dist/PreviewFile";

const useStyles = makeStyles((theme) => ({
  timestamp: (props) => {
    return {
      fontSize: 10,
      color: props.textColor,
      fontWeight: 300,
    };
  },
  inboxHeader: (props) => {
    return {
      padding: "16px 24px",
      left: "0px",
      top: "0px",
      color: props.headerColor,
      borderBottom: props.border,
      borderRadius: `${props.borderRadius}px ${props.borderRadius}px 0px 0px`,
      background: props.background,
    };
  },
  inboxTitle: (props) => {
    return {
      textAlign: "left",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "24px",
      color: props.headerColor,
    };
  },
  timestampLg: {
    fontSize: 12,
    color: "#383538",
  },

  address: {
    background: "#FFEEB8",
    padding: "2px 5px",
  },

  addressFrom: {
    fontSize: 12,
    fontWeight: 500,
    marginLeft: "2px",
  },

  value: {
    fontSize: 16,
  },
  field: (props) => {
    return {
      background: props.fieldBackground,
      padding: "8px 12px",
      marginRight: 10,
      color: props.textColor,
      fontWeight: 400,
      fontSize: "12px",
      whiteSpace: "nowrap",
      border: props.border,
      borderRadius: "5px",
    };
  },
  header: (props) => {
    return {
      background: props.headerBackground,
      color: props.headerColor,
      padding: 10,
      borderRadius: "30px 10px 0px 0px",
      marginBottom: "16px",
    };
  },
  messageBox: (props) => {
    return {
      fontWeight: 700,
      overflow: "auto",
      backgroundColor: props.messageBoxBackground,
      padding: "0px 10px",
      paddingTop: 10,
    };
  },
  sendField: (props) => {
    return {
      padding: "23px 26px",
      background: props.sendFieldBackground,
      borderRadius: `0px 0px ${props.borderRadius}px ${props.borderRadius}px`,
    };
  },
}));

const Chat = ({ selectedTicket, entityName }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { socket, sendMessage } = useConnectWallet();
  const [message, setMessage] = useState("");
  const [openImg, setOpenImg] = useState("");
  const [files, setFiles] = useState([]);
  const [pastMsgs, setPastMessages] = useState([]);
  const [newMsg, setNewMsg] = useState("");
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const surveyConfig = useSelector(selectSurveyConfig);

  useEffect(() => {
    if (selectedTicket) {
      let selectedUserId = _.get(selectedTicket, "user");
      if (!socket) return;
      socket.emit("init", {
        userId: selectedUserId,
      });

      socket.on("adminResponse", (data) => {
        setNewMsg(data);
      });

      // 當admin收回訊息
      socket.on("recallByAdmin", (socketData) => {
        // 比對過去的訊息是否是收回的那個, 是的話就替換掉
        const updateRecallItem = (prevChatMsgs) => {
          console.log("prevChatMsgs: ", prevChatMsgs);
          const newArr = prevChatMsgs.map((prevChatMsg) =>
            prevChatMsg._id === socketData._id
              ? { ...socketData, msgId: socketData._id }
              : prevChatMsg
          );
          return newArr;
        };

        setPastMessages((prevChatMsgs) => updateRecallItem(prevChatMsgs));
      });
      return () => {
        socket.off("connect");
        socket.off("adminResponse");
        socket.off("clientCreateTicket");
        socket.off("recallByAdmin");
      };
    }
  }, [selectedTicket]);

  useEffect(() => {
    let newMsgTicketId = newMsg && newMsg.ticket;
    let currentTicketId = selectedTicket && selectedTicket._id;
    if (newMsgTicketId == currentTicketId) {
      setPastMessages([...pastMsgs, newMsg]);
    }
  }, [newMsg]);

  useEffect(() => {
    scrollToBottom();
  }, [pastMsgs, selectedTicket]);

  useEffect(() => {
    if (selectedTicket) {
      getMessageListByTicket(selectedTicket._id);
    }
  }, [selectedTicket?._id]);

  const getMessageListByTicket = async (ticketId) => {
    setLoading(true);
    try {
      let messages = await axios.get(
        `${apiUrl}api/messages?ticketId=${ticketId}&entityName=${entityName}`
      );
      setPastMessages(messages.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const scrollToBottom = async () => {
    const offsetBottom = messagesEndRef.current?.scrollHeight;
    messagesEndRef.current?.scrollTo({ top: offsetBottom });
  };
  const setMessageData = (value) => {
    if (!value || value.trim().length == 0) {
      value = value.trim();
    }
    setMessage(value);
  };

  const doSendMessage = async () => {
    if ((message && message.trim().length !== 0) || files.length > 0) {
      let selectedUserId = selectedTicket.user;
      const msgId = uuidv4();
      let data = {
        userId: selectedUserId,
        message: message,
        entityName,
        ticketId: selectedTicket._id,
        files: files,
        msgId,
        status: "sending",
        created: new Date(),
      };
      setPastMessages([...pastMsgs, data]);
      setMessage("");
      setFiles([]);

      setTimeout(() => {
        setPastMessages((currentMessages) =>
          currentMessages.map((msg) =>
            msg.msgId === msgId && msg.status === "sending"
              ? { ...msg, status: "failed" }
              : msg
          )
        );
      }, 3000);
      scrollToBottom();

      sendMessage(data, (result) => {
        setPastMessages((currentMessages) =>
          currentMessages.map((msg) =>
            msg.msgId === result.msgId ? { ...msg, status: "success" } : msg
          )
        );
      });
    }
  };

  const handleKey = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      doSendMessage();
    }
  };

  const renderMessageBox = (isDone) => {
    let adminIndex = -1;
    let userIndex = -1;
    return (
      <div
        ref={messagesEndRef}
        className={classes.messageBox}
        style={{
          height: isDone ? "71vh" : "49vh",
          borderRadius: isDone
            ? `0 0 ${theme.borderRadius}px ${theme.borderRadius}px`
            : 0,
        }}
      >
        {pastMsgs &&
          pastMsgs.map((data, index) => {
            let lastOne = index - 1 == adminIndex ? "admin" : "user";
            let lastData = pastMsgs[index - 1];
            if (index == 0) lastOne = !data.isAdminResponse ? "admin" : "user";
            if (data.isAdminResponse) adminIndex = index;
            else userIndex = index;

            return (
              <ChatItem
                data={data}
                entityName={entityName}
                selectedTicket={selectedTicket}
                setOpenImg={setOpenImg}
                index={index}
                lastOne={lastOne}
                lastData={lastData}
              />
            );
          })}
        {SurveyMsg.shouldRender({ selectedTicket, surveyConfig }) && (
          <SurveyMsg
            selectedTicket={selectedTicket}
            surveyConfig={surveyConfig}
            entityName={entityName}
          />
        )}
      </div>
    );
  };
  const { enqueueSnackbar } = useSnackbar();

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar({
          message: "Text copied to clipboard",
          options: {
            variant: "success",
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar({
          message: "Failed to copy text to clipboard:" + error,
          options: {
            variant: "error",
          },
        });
      });
  };

  const isDone = _.toUpper(_.get(selectedTicket, "status")) == "DONE";
  return (
    <div
      style={{
        border: theme.border,
        borderRadius: theme.borderRadius,
        marginLeft: 10,
        position: "relative",
        height: "100%",
      }}
    >
      {!selectedTicket && (
        <div
          style={{
            background: "black",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            opacity: 0.7,
            zIndex: 1000,
            color: "white",
            fontWeight: 700,
            fontSize: 18,
            textAlign: "center",
            border: theme.border,
            borderRadius: theme.borderRadius,
          }}
        >
          Please select ticket
          <br /> <br />
          {"<---"}
        </div>
      )}
      <div>
        <div className={classes.inboxHeader}>
          <Grid
            container
            style={{
              fontWeight: 600,
              color: theme.headerColor,
              fontSize: 12,
              alignItems: "center",
            }}
          >
            <Grid item xs={4} md={4} style={{ fontSize: 13 }}>
              {selectedTicket && (
                <>
                  Created:
                  {moment(_.get(selectedTicket, "created", "")).format(
                    " YYYY/MM/DD HH:mm"
                  )}
                  <div style={{ marginTop: 10 }}>
                    <TicketType ticketType={_.get(selectedTicket, "type")} />
                  </div>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              className={classes.inboxTitle}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {_.truncate(_.get(selectedTicket, "title", "..."), {
                length: 40,
              })}
              {selectedTicket?._id && (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Ticket ID: {getShortAddress(selectedTicket._id)}
                  <i
                    className="meta-crm-icon-ic_copy2 font-size-12"
                    style={{ marginLeft: "4px", cursor: "pointer" }}
                    onClick={() => copyText(selectedTicket._id)}
                  />
                </p>
              )}
            </Grid>
            <Grid item xs={4} md={4} style={{ textAlign: "right" }}>
              <div>Status:</div>
              <div style={{ marginTop: 10 }}>
                <TicketStatus ticket={selectedTicket} />
              </div>
              {/* <div
                style={{
                  marginTop: 20,
                  marginRight: 10,
                  color: theme.statusHeader || "",
                }}
              >
                Status
              </div> */}
            </Grid>
          </Grid>
        </div>
        <Modal
          open={openImg}
          className={"modal"}
          onClose={() => setOpenImg("")}
        >
          <div style={{ position: "relative", outline: "none" }}>
            <IconButton
              onClick={() => setOpenImg("")}
              style={{
                position: "absolute",
                right: 0,
                top: "-50px",
                padding: 0,
              }}
            >
              <img src={require("assets/img/close.svg").default} />
            </IconButton>
            <img
              src={openImg}
              style={{
                width: "80vw",
                height: "80vh",
                objectFit: "contain",
              }}
            />
            <div
              style={{
                textAlign: "right",
                maxWidth: "80vw",
                margin: "0 auto",
              }}
            >
              <a href={openImg} download target={"_blank"} rel="noreferrer">
                <img src={require("assets/img/download.svg").default} />
              </a>
            </div>
          </div>
        </Modal>
        <div style={{ position: "relative" }}>
          <Loading open={loading} fullScreen={false} />
          {renderMessageBox(isDone)}
          {!isDone && (
            <div
              style={{
                borderTop: theme.border,
              }}
            >
              <div className={classes.sendField}>
                <div style={{ background: "white" }}>
                  <CustomOutlinedInput
                    fullWidth
                    multiline
                    noLine
                    style={{
                      borderRadius: 0,
                    }}
                    rows={4}
                    placeholder="Type here to reply..."
                    onKeyDown={handleKey}
                    value={message}
                    onChange={(e) => setMessageData(e.target.value)}
                    innerRender={
                      <Box px="10px">
                        <PreviewFile
                          files={files}
                          onDelete={(file, index) => {
                            const newFiles = [...files];
                            newFiles.splice(index, 1);
                            setFiles(newFiles);
                          }}
                        />
                      </Box>
                    }
                  />
                </div>
                <div
                  style={{
                    padding: "10px 0 0",
                  }}
                >
                  <div className="betweenRow" style={{ alignItems: "center" }}>
                    <IconFileUploader
                      uploadFile={(file) =>
                        setFiles((prev) => [...prev, ...file])
                      }
                      inputProps={{
                        accept: `image/jpeg,
                          image/jpg,
                          image/png,
                          image/webp,
                          image/heic,
                          image/jfif,
                          image/gif,
                          application/pdf,
                          video/mp4,
                          video/quicktime`,
                      }}
                      sx={{
                        color: theme.textColor,
                        borderColor: theme.borderColor,
                      }}
                    />
                    <Button
                      onClick={doSendMessage}
                      disabled={!message && files.length == 0}
                      color="primary"
                      style={{
                        width: 122,
                        height: 36,
                        borderRadius: "3px",
                        margin: 0,
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chat;
