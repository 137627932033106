import React, { useEffect, useState, useRef } from "react";
import { StyledMenu, StyledCustomListItem } from "./MemberMenu.styles";
import { useTheme } from "@mui/styles";

const MemberMenu = ({
  anchorMenu,
  onCloseMemberClose,
  onSignoutAndRedirect,
}) => {
  const customTheme = useTheme();

  return (
    <StyledMenu
      customTheme={customTheme}
      open={Boolean(anchorMenu)}
      PaperProps={{ style: { marginTop: 5 } }}
      anchorEl={anchorMenu}
      onClose={onCloseMemberClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div>
        <StyledCustomListItem
          onClick={onSignoutAndRedirect}
          customTheme={customTheme}
          label={
            <div className="custom-list-item-label">
              {" "}
              <i className="meta-crm-icon-ic_signOut font-size-14" />
              <div>Sign Out</div>
            </div>
          }
        />
      </div>
    </StyledMenu>
  );
};

export default MemberMenu;
