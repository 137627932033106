import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import Linkify from "linkify-react";
import moment from "moment";
import Box from "@mui/material/Box";
import { apiUrl } from "features/configure";
import { useSnackbar } from "features/common/redux/snackbar";
import {
  StyledAdminMessage,
  StyledAdminMessageTime,
  StyledMessageRow,
  SurveyScoreGroup,
  SurveyScoreItem,
} from "./Chat.styles";
import { IC_STAR, MAX_SURVEY_SCORE, emojiList } from "./SurveyConstants";

function SurveyMsg({ selectedTicket, surveyConfig, entityName }) {
  const options = {
    target: (href) => {
      const { hostname } = window.location;
      if (hostname && href.includes(hostname)) {
        return "";
      }
      return "_blank";
    },
    attributes: {
      rel: "noreferrer noopener",
    },
  };
  const [emojiHoverIndex, setEmojiHoverIndex] = useState(-1);
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const [surveyScore, setSurveyScore] = useState(
    _.get(selectedTicket, "surveyScore", null)
  );
  const surveySendingTime = _.get(selectedTicket, "surveySendingTime", null);
  const surveyBtnStyle = _.get(surveyConfig, "surveyBtnStyle");
  const surveyMsg = _.get(surveyConfig, "surveyMsg");
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmitSurveyScore(score) {
    try {
      const result = await axios.post(
        `${apiUrl}api/ticket/updateSurveyScore`,
        {
          ticketId: selectedTicket._id,
          surveyScore: score,
          entityName: entityName,
        },
        { responseType: "json" }
      );
      if (result.data.success) {
        const updatedTicket = result.data.ticket;
        setSurveyScore(_.get(updatedTicket, "surveyScore", null));
      } else {
        enqueueSnackbar({
          message: "Failed to submit",
          options: {
            variant: "error",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to submit",
        options: {
          variant: "error",
        },
      });
    }
  }

  function renderStars(count) {
    const stars = [];
    for (let i = 0; i < count; i++) {
      const ele = (
        <span key={i} style={{ fontSize: computer ? 16 : 14 }}>
          {IC_STAR}
        </span>
      );
      stars.push(ele);
    }
    return stars;
  }

  function renderEmoji(idx) {
    const emojiDetail = emojiList[idx];
    return (
      <>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: computer ? 24 : 20 }}>
            {emojiDetail.emoji}
          </div>
        </span>
      </>
    );
  }

  const renderSurveyMsg = () => {
    if (surveyScore) {
      return (
        <Linkify options={options}>
          {"🎉 Thank you for your rate! See you next time!"}
        </Linkify>
      );
    }

    if (surveyBtnStyle === "star") {
      const starCounts = Array.from(
        { length: MAX_SURVEY_SCORE },
        (_, index) => index + 1
      );
      return (
        <div>
          <Linkify options={options}>{surveyMsg}</Linkify>
          <SurveyScoreGroup>
            {starCounts.map((count, index) => (
              <SurveyScoreItem
                style={{ height: 32, width: 40 + 22 * index }}
                key={index}
                onClick={() => handleSubmitSurveyScore(index + 1)}
              >
                {renderStars(count)}
              </SurveyScoreItem>
            ))}
          </SurveyScoreGroup>
        </div>
      );
    }

    if (surveyBtnStyle === "emoji") {
      return (
        <div>
          <div style={{ maxWidth: 304 }}>
            <Linkify options={options}>{surveyMsg}</Linkify>
          </div>
          <SurveyScoreGroup style={{ marginBottom: 10, marginLeft: 5 }}>
            {emojiList.map((_, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <SurveyScoreItem
                  style={{ height: 48, width: 48 }}
                  key={index}
                  onClick={() => handleSubmitSurveyScore(index + 1)}
                  onMouseEnter={() => setEmojiHoverIndex(index)}
                  onMouseLeave={() => setEmojiHoverIndex(-1)}
                >
                  {renderEmoji(index)}
                </SurveyScoreItem>
                {emojiHoverIndex === index && (
                  <div
                    style={{
                      fontSize: 10,
                      color: theme.customColors.grey[500],
                      top: 54,
                      position: "absolute",
                      textWrap: "nowrap",
                    }}
                  >
                    {emojiList[index].text}
                  </div>
                )}
              </div>
            ))}
          </SurveyScoreGroup>
        </div>
      );
    }
  };

  const renderTime = () => {
    if (!surveySendingTime) return false;
    return (
      <>
        {moment(surveySendingTime).format("YYYY/MM/DD")}
        <br /> {moment(surveySendingTime).format("HH:mm")}
      </>
    );
  };

  return (
    <div>
      <Box mb="18px">
        <StyledMessageRow
          status={"success"}
          style={{ justifyContent: "flex-start" }}
        >
          <StyledAdminMessage style={{ maxWidth: "none" }} sending={false}>
            {renderSurveyMsg()}
          </StyledAdminMessage>
          <StyledAdminMessageTime theme={theme}>
            {renderTime()}
          </StyledAdminMessageTime>
        </StyledMessageRow>
      </Box>
    </div>
  );
}

SurveyMsg.shouldRender = ({ selectedTicket, surveyConfig }) => {
  const isSurveyEnabled = _.get(surveyConfig, "isSurveyEnabled", false);
  const isSurveySent = _.get(selectedTicket, "isSurveySent", false);
  return isSurveyEnabled && isSurveySent;
};

export default SurveyMsg;
