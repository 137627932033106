import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  AUTH_CHECK_BEGIN,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAILURE,
  LOG_OUT_START,
  INIT_SOCKET,
  SEND_MESSAGE,
  GET_AUTH_INFO_BEGIN,
  GET_AUTH_INFO_SUCCESS,
  GET_AUTH_INFO_FAILURE,
} from "./constants";
import _ from "lodash";
import axios from "axios";
import { useDisconnect } from "wagmi";
import io from "socket.io-client";
import { apiUrl, socketIOUrl } from "features/configure";
import FormData from "form-data";
axios.defaults.withCredentials = true;
const options = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const covertToFile = async (dataURI, fileName) => {
  if (!dataURI || typeof dataURI != "string") return null;
  if (
    !dataURI.startsWith("data:image/") &&
    !dataURI.startsWith("data:application/pdf")
  ) {
    return null;
  }
  const res = await fetch(dataURI, { mode: "no-cors" });
  const buf = await res.arrayBuffer();
  const file = new File([buf], fileName, {
    type: "image/png",
  });
  return file;
};

export function authCheck(entityName) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_CHECK_BEGIN,
    });

    try {
      const res = await axios.get(
        `${apiUrl}api/session/user/authCheck?entityName=` + entityName
      );
      dispatch({
        type: AUTH_CHECK_SUCCESS,
        authorized: res.data.authorized,
      });
      const state = getState();
      initSocket(dispatch, entityName, state.home.socket);
    } catch (err) {
      dispatch({
        type: AUTH_CHECK_FAILURE,
      });
      // console.log(err);
    }
  };
}

export function getAuthenticatedInfo(entityName) {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_AUTH_INFO_BEGIN,
    });

    try {
      if (!entityName) return;

      const res = await axios.get(
        `${apiUrl}api/session/user/info?entityName=` + entityName
      );
      dispatch({
        type: GET_AUTH_INFO_SUCCESS,
        userData: res.data,
      });
      const state = getState();
      initSocket(dispatch, entityName, state.home.socket);
    } catch (err) {
      dispatch({
        type: GET_AUTH_INFO_FAILURE,
      });
      // console.log(err);
    }
  };
}

const initSocket = (dispatch, entityName, nowSocket) => {
  if (!nowSocket) {
    const socket = io(socketIOUrl + entityName.toLowerCase());
    socket.emit("init", {
      address: "admin",
    });
    dispatch({
      type: INIT_SOCKET,
      socket: socket,
    });
  }
};

const signoutAction = async () => {
  await axios.get(`${apiUrl}api/session/sign-out`);
  localStorage.removeItem("access_token");
  delete axios.defaults.headers.common["Authorization"];
};
export function doMessage(socket, data, callback) {
  return async (dispatch) => {
    try {
      const { files, entityName } = data;
      if (files && files.length > 0) {
        let postFormData = new FormData();
        files.forEach((fileItem) => {
          postFormData.append("chat", fileItem);
        });

        postFormData.append("entityName", entityName);
        const uploadResult = await axios.post(
          apiUrl + "api/gcp/uploadChat",
          postFormData,
          options
        );
        const chatFiles = _.get(uploadResult.data, "chat");
        data["files"] = chatFiles;
        data["imagesUrl"] = chatFiles;
      }
      socket.emit("message", data, callback);
      dispatch({ type: SEND_MESSAGE });
      return data;
    } catch (err) {
      console.log(err);
    }
  };
}

export function signout(disconnect) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOG_OUT_START });
      await signoutAction();
      disconnect();
      dispatch({ type: AUTH_CHECK_FAILURE });
    } catch (err) {
      // console.log(err);
    }
  };
}

export function useConnectWallet() {
  const dispatch = useDispatch();
  const {
    userData,
    auth,
    checkAuth,
    authCheckPending,
    logoutLoading,
    authCheckDone,
    socket,
    getAuthenticatedInfoPending,
    getAuthenticatedInfoDone,
  } = useSelector(
    (state) => ({
      userData: state.home.userData,
      auth: state.home.auth,
      checkAuth: state.home.checkAuth,
      authCheckPending: state.home.authCheckPending,
      logoutLoading: state.home.logoutLoading,
      authCheckDone: state.home.authCheckDone,
      socket: state.home.socket,
      getAuthenticatedInfoPending: state.home.getAuthenticatedInfoPending,
      getAuthenticatedInfoDone: state.home.getAuthenticatedInfoDone,
    }),
    shallowEqual
  );
  const authAction = useCallback(
    (data) => dispatch(authCheck(data)),
    [dispatch]
  );

  const getAuthenticatedInfoAction = useCallback(
    (data) => dispatch(getAuthenticatedInfo(data)),
    [dispatch]
  );

  const messageAction = useCallback(
    (data, callback) => dispatch(doMessage(socket, data, callback)),
    [dispatch, socket]
  );

  const { disconnect } = useDisconnect();
  const signoutAction = useCallback(
    () => dispatch(signout(disconnect)),
    [disconnect, dispatch]
  );
  return {
    userData,
    auth,
    checkAuth,
    authCheckPending,
    authCheckDone,
    logoutLoading,
    socket,
    getAuthenticatedInfoPending,
    getAuthenticatedInfoDone,
    sendMessage: messageAction,
    authCheck: authAction,
    getAuthenticatedInfo: getAuthenticatedInfoAction,
    signout: signoutAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_CHECK_BEGIN:
      return {
        ...state,
        authCheckPending: true,
      };
    case AUTH_CHECK_SUCCESS:
      return {
        ...state,
        auth: action.authorized,
        checkAuth: true,
        authCheckPending: false,
        authCheckDone: true,
      };
    case LOG_OUT_START:
      return {
        ...state,
        logoutLoading: true,
      };
    case AUTH_CHECK_FAILURE:
      return {
        ...state,
        auth: false,
        checkAuth: true,
        authCheckPending: false,
        logoutLoading: false,
      };
    case GET_AUTH_INFO_BEGIN:
      return {
        ...state,
        getAuthenticatedInfoPending: true,
      };
    case GET_AUTH_INFO_SUCCESS:
      const newData = {
        ...state,
        getAuthenticatedInfoDone: true,
        getAuthenticatedInfoPending: false,
      };
      if (
        _.get(action, "userData.address") !==
        _.get(state, "userData.address", "NoAddress")
      ) {
        newData.userData = action.userData;
      }

      return newData;
    case GET_AUTH_INFO_FAILURE:
      return {
        ...state,
        userData: undefined,
        getAuthenticatedInfoPending: false,
      };
    case INIT_SOCKET:
      return {
        ...state,
        socket: action.socket,
      };
    default:
      return state;
  }
}
