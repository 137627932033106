import React from "react";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import Button from "components/CustomButtons/Button";
import IconIcDiscord from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcDiscord";

const SocialIcons = ({ white = false }) => {
  return (
    <div style={{}}>
      <Button
        color={"social"}
        onClick={() => window.open("http://bit.ly/3uR9OBr")}
      >
        <i className="meta-crm-icon-ic_telegram font-size-18" />
      </Button>
      <Button
        color={"social"}
        white={white}
        onClick={() => window.open("https://discord.gg/EVuwAzhKxu")}
      >
        <i className="meta-crm-icon-ic_discord font-size-24" />
      </Button>
      <Button
        color={"social"}
        onClick={() => window.open("https://twitter.com/metacrm_inc")}
      >
        <i className="meta-crm-icon-ic_x-1 font-size-18" />
      </Button>
    </div>
  );
};

export default SocialIcons;
