import { byDecimals } from "./bignumber";
import moment from "moment";
import _ from "lodash";
let trimReg = /(^\s*)|(\s*$)/g;

export function isEmpty(key) {
  if (key === undefined || key === "" || key === null) {
    return true;
  }
  if (typeof key === "string") {
    key = key.replace(trimReg, "");
    return (
      key === "" ||
      key === null ||
      key === "null" ||
      key === undefined ||
      key === "undefined"
    );
  } else if (typeof key === "undefined") {
    return true;
  } else if (typeof key == "object") {
    for (let i in key) {
      return false;
    }
    return true;
  } else if (typeof key == "boolean") {
    return false;
  }
}

let inputReg = /[a-z]/i;
export function inputLimitPass(value, tokenDecimals) {
  let valueArr = value.split(".");
  return !(
    inputReg.test(value) ||
    (valueArr.length === 2 && valueArr[1].length > tokenDecimals)
  );
}

export function inputFinalVal(value, total, tokenDecimals) {
  let inputVal = Number(value.replaceAll(",", ""));
  return inputVal > total
    ? byDecimals(total, 0).toFormat(tokenDecimals)
    : value;
}

export const shouldHideFromHarvest = (vaultName) => {
  // FIXME: hidden until we implement an 'advanced' toggle
  // return HarvestBlacklistVaultIds.includes(vaultName);
  return true;
};

// const HarvestBlacklistVaultIds = [
//   'bifi-maxi',
//   'fortube-fil',
//   'fortube-atom',
//   'fortube-xtz',
//   'fortube-busd',
//   'fortube-link',
//   'fortube-dot',
//   'fortube-usdt',
//   'fortube-eth',
//   'fortube-btcb',
//   'fry-burger-v2',
// ];

export const converAprToApy = (apr) => {
  return Math.pow(1 + apr / 365, 365) - 1;
};

export const converAprStringToApyString = (aprString) => {
  const apr = parseFloat(aprString.replace("%", "")) / 100;
  return parseFloat(converAprToApy(apr) * 100).toFixed(2) + "%";
};

export const convertApyFloatToString = (apyFloat) => {
  return (apyFloat * 100).toFixed(2) + "%";
};

export const convertAprFloatToString = (aprFloat) => {
  return parseFloat(converAprToApy(aprFloat) * 100).toFixed(2) + "%";
};

export const formatDateTime = (date) => {
  if (!moment(date).isValid()) return "-";
  return moment(date).format("YYYY/MM/DD HH:mm");
};

export const getShortAddress = (address) => {
  if (!address) return "-";
  return `${address.slice(0, 6)}......${address.slice(-4)}`;
};

const userIconAvatars = [
  "ic_alpacaAllWhite.svg",
  "ic_alpacaPurple.svg",
  "ic_alpacaBlack.svg",
  "ic_alpacaBlue.svg",
  "ic_alpacaGreen.svg",
  "ic_alpacaPink.svg",
  "ic_alpacaWhite.svg",
  "ic_alpacaYellow.svg",
];
export const getUserAvatarIcon = (user, entityName, theme) => {
  if (entityName === "magpie") {
    return theme.userIcon;
  }
  if (_.isEmpty(user)) {
    return "ic_alpacaAllWhite.svg";
  }
  const lastChar = user.charCodeAt(user.length - 1);
  const imageIndex = lastChar % 8;
  return userIconAvatars[imageIndex];
};

export const parseCustomDomainUrl = (isCustomDomain, entityName, url) => {
  if (isCustomDomain) {
    return url;
  }
  if (!entityName) {
    return "/";
  }
  return `/${entityName}${url}`;
};
