import config from "../../config";
import appThemeDefault from "features/home/jss/appTheme_default";

export {
  erc20ABI,
  erc721ABI,
  distributeABI,
  distributeNFTABI,
  masterMagpieABI,
  vlMGPABI,
} from "./abi";
export { contracts } from "./contracts";
export { pools, rewardToCoingeckoIdMap } from "./pools";

export const apiUrl = config.apiUrl;
export const socketIOUrl = config.socketIOUrl;
export const defaultThemeConfig = {
  theme: (mode) => appThemeDefault(mode),
  headerLink: "/",
  headerLogo: require("assets/img/header.svg").default,
};
export const nativeAddress = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
