import React, { useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { ListItem, ListItemSecondaryAction } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItems: {
    color: `${theme.palette.text.primary}`,
    marginLeft: 0,
    fontSize: 14,
    fontWeight: 700,
    marginTop: 0,
    minHeight: 45,
    margin: "0px",
    "&.Mui-selected": {
      backgroundColor: `#CBE6FF`,
    },
    "&:hover": { backgroundColor: `#CBE6FF` },
    "&:focus": {
      backgroundColor: `#CBE6FF`,
      "& > img:first-child": {},
    },
  },
  listHeader: {
    fontSize: 16,
    fontWeight: 900,
    minHeight: 38,
    textAlign: "center",
    padding: 20,
    borderBottom: "1px solid rgba(56, 53, 56, 0.5)",
    "&:hover": { backgroundColor: `transparent` },
  },
  listContent: {
    fontSize: 14,
    fontWeight: 700,
  },
  error: {
    border: "1px solid #FF3296",
  },
  formPage: {
    // backgroundColor: "#E6E6E6",
    // marginTop: 5,
    // marginBottom: 5,
  },
  selected: { backgroundColor: "#CBE6FF" },
  rightSelected: {
    backgroundColor: "#F3F3F3",
    borderRight: "7px solid #FF3CA0",
  },
  disabled: {
    color: "#CCCCCC",
    borderBottom: "1px solid #CCCCCC",
  },
}));

export default function CustomListItem({
  label,
  icon,
  onClick,
  header,
  content,
  rightIcon,
  formPage,
  error,
  selected,
  disabled,
  right,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <ListItem
      button={!header && !content}
      onClick={onClick}
      classes={{
        root: `${classes.listItems} ${header && classes.listHeader} ${
          content && classes.listContent
        } ${formPage && classes.formPage} ${
          selected && (right ? classes.rightSelected : classes.selected)
        } ${error && classes.error} ${disabled && classes.disabled}`,
      }}
      {...props}
    >
      <div>{icon && icon}</div>
      <div style={{ overflow: "hidden" }}>{label}</div>
      <ListItemSecondaryAction className="row">
        {rightIcon && rightIcon}
        {error && (
          <ErrorOutlineIcon
            style={{ color: "#FF3296", verticalAlign: "middle", margin: 5 }}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
