import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import IconLogoCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconLogoCRM";
import Button from "components/CustomButtons/Button";
import { useIntl } from "react-intl";
const useStyles = makeStyles((theme) => ({}));

const NoAuth = ({}) => {
  const { formatMessage } = useIntl();
  return (
    <div
      style={{
        height: `calc(100vh - 130px)`,
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ fontWeight: 900, fontSize: 20 }} className="startRow">
        {formatMessage({ id: "supportCenter.signIn.title" })}{" "}
        <IconLogoCRM height={24} style={{ margin: 5 }} />{" "}
        {formatMessage({ id: "global.company.name" })}
      </div>
      <div
        style={{
          fontWeight: 300,
          fontSize: 16,
          marginBottom: 60,
          marginTop: 12,
        }}
      >
        {" "}
        {formatMessage({ id: "supportCenter.signIn.description" })}
      </div>
      <Button
        color="primary"
        onClick={() => window.open("https://metacrm.inc")}
      >
        {formatMessage({ id: "supportCenter.signIn.btn" })}
      </Button>
    </div>
  );
};

export default NoAuth;
