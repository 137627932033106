import _ from "lodash";

export const IC_STAR = "⭐️";
export const MAX_SURVEY_SCORE = 5;
export const DEFAULT_SURVEY_MSG = {
  star: {
    msg: "How would you rate the support I provide?",
  },
  emoji: {
    msg: "Please choose the option that best reflects your current mood:",
  },
};
export const emojiList = [
  {
    emoji: "🤬",
    text: "Very Unhappy",
  },
  {
    emoji: "😡",
    text: "Unhappy",
  },
  {
    emoji: "😶",
    text: "Neutral",
  },
  {
    emoji: "😃",
    text: "Happy",
  },
  {
    emoji: "😍",
    text: "Very Happy",
  },
];

export const getCleanSurveyMsg = ({ type, surveyConfig }) => {
  const surveyMsg = surveyConfig.surveyMsg;
  switch (type) {
    case "star": {
      return surveyMsg?.length ? surveyMsg : DEFAULT_SURVEY_MSG.star.msg;
    }
    case "emoji": {
      return surveyMsg?.length ? surveyMsg : DEFAULT_SURVEY_MSG.emoji.msg;
    }
    default: {
      throw new Error("Invalid survey msg type provided");
    }
  }
};
