import { reducer as fetchTicketListReducer } from "./fetchTicketList";
import { reducer as fetchEntityConfigReducer } from "./fetchEntityConfig";
const reducers = [fetchTicketListReducer, fetchEntityConfigReducer];

const initialState = {
  memberList: [],
  ticketList: [],
  filter: { status: "ALL" },
  filterTicketList: [],
  userList: [],
  orderData: {
    order: "desc",
    orderBy: "",
  },
  entityConfig: {
    ticketTypes: [],
    signatureSignInDisabled: false,
  },
  theme: {
    customColor: {},
    name: "",
  },
};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
