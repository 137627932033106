import { reducer as connectWalletReducer } from "./connectWallet";
import { reducer as fetchDashboardReducer } from "./fetchDashboard";
import { reducer as userReducer } from "./userSelect";

const reducers = [connectWalletReducer, fetchDashboardReducer, userReducer];
let currentLocation = window.location.hash;

const initialState = {
  address: "",
  connected: false,
  logoutLoading: false,
  // user: currentLocation.includes("creator") ? "creator" : "user",
  data: {},
  networkId: Number(process.env.REACT_APP_NETWORK_ID),
};

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
