import React from "react";
import _ from "lodash";
import { useTheme } from "@mui/styles";

const TicketStatus = ({ ticket }) => {
  const theme = useTheme();
  return (
    <span
      style={{
        background: theme.statusBackground,
        fontWeight: theme.fontWeight.BOLD,
        borderRadius: theme.borderRadius.radiusNormal,
        fontSize: 13,
        boxShadow: theme.boxShadow,
        padding: theme.statusPadding,
        color: theme.statusColor,
        border: theme.border,
      }}
    >
      {_.toUpper(_.get(ticket, "status", "..."))}
    </span>
  );
};

export default TicketStatus;
