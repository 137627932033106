import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import LandingHeader from "features/home/components/LandingHeader";
import { useNavigate } from "react-router-dom";
import Button from "components/CustomButtons/Button";

const NotFound = ({}) => {
  const navigate = useNavigate();
  return (
    <div
      className="loadingPaper"
      style={{
        background: "white",
        textAlign: "center",
        height: "calc(100vh - 60px)",
      }}
    >
      <LandingHeader />
      <div>
        <img src={require("assets/img/404.svg").default} />
        <div
          style={{
            marginTop: 32,
            marginBottom: 20,
            fontSize: 36,
            fontWeight: 800,
          }}
        >
          This page is lost.
        </div>
        <div style={{ fontWeight: 500, fontSize: 18 }}>
          We’ve explored deep and wide,
          <br /> but we can’t find the page you were looking for.
        </div>
        <Button
          color="secondary"
          onClick={() => navigate(-1)}
          style={{
            border: "0.5px solid #A5A5A5",
            borderRadius: 5,
            marginTop: 32,
            height: 50,
          }}
        >
          {" "}
          <img
            src={require("assets/img/backButton.svg").default}
            style={{ marginRight: 5 }}
          />
          Navigate Back
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
