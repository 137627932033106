import React, { useEffect, useState, useMemo } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { hot, setConfig } from "react-hot-loader";
import store from "./common/store";
import router from "./route";
import { SnackbarProvider } from "notistack";
import CustomThemeProvider from "features/home/CustomThemeProvider";
import { Helmet } from "react-helmet";
import { arbitrum, mainnet, polygon, optimism, bsc } from "wagmi/chains";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { publicProvider } from "wagmi/providers/public";
import { IntlProvider } from "react-intl";
import { enMessages, zhTWMessages } from "locales";
import * as Sentry from "@sentry/react";
import {
  WalletProvider,
  MartianWalletAdapter,
  AptosWalletAdapter,
  RiseWalletAdapter,
  PontemWalletAdapter,
} from "@manahippo/aptos-wallet-adapter";
import {
  ConnectionProvider,
  WalletProvider as SolanaWalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: "https://8265464c886a434bb2f208cd40e6ca5e@o4504931933093888.ingest.sentry.io/4504972301565952",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const messages = {
  en: enMessages,
  "zh-TW": zhTWMessages,
};

const wallets = [
  new PontemWalletAdapter(),
  new MartianWalletAdapter(),
  new RiseWalletAdapter(),
  new AptosWalletAdapter(),
];

const solanaWallets = [new SolflareWalletAdapter(), new PhantomWalletAdapter()];

const projectId = "e8f69f6a94a0dc82705e2e74954640c7"; //walletConnet project ID

const { publicClient, chains } = configureChains(
  [mainnet, polygon, optimism, arbitrum, bsc],
  [
    alchemyProvider({
      apiKey: "eg4gCkiDZxQ1X_D4vDk0Mz30lDwWQyVm",
      priority: 0,
    }),
    publicProvider(),
  ]
);

const metadata = {
  name: "MetaCRM",
  description: "MetaCRM Login",
  url: "https://metacrm.inc",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const connectors = [
  new InjectedConnector({
    chains,
    options: {
      name: "Browser Wallet",
      shimDisconnect: true,
    },
  }),
  new MetaMaskConnector({
    chains,
    options: {
      name: "MetaMask",
      shimDisconnect: true,
      UNSTABLE_shimOnConnectSelectAccount: true,
    },
  }),
  new WalletConnectConnector({
    chains,
    options: {
      projectId,
      metadata,
    },
  }),
];

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

setConfig({
  logLevel: "debug",
});

const renderMetadata = (
  title = "MetaCRM Support Center",
  desc = "Made with ♥️ in MetaVerse",
  url,
  imageURL
) => {
  if (!url) {
    url = window.location.href;
  }
  if (!imageURL) {
    imageURL = window.location.origin + require("assets/img/defaultBanner.png");
  }
  if (process.env.REACT_APP_ENTITY == "curve") {
    imageURL =
      window.location.origin + require("assets/img/curveBanner.png").default;
    title = "Curve Support Center";
    desc = "Powered by MetaCRM";
  } else if (process.env.REACT_APP_ENTITY == "radiant") {
    imageURL =
      window.location.origin + require("assets/img/radiantBanner.png").default;
    title = "Radiant Support Center";
    desc = "Powered by MetaCRM";
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="fragment" content="!" />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={desc} />
      <meta itemprop="image" content={imageURL} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imageURL} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={imageURL} />
      <meta name="description" content={desc} />
    </Helmet>
  );
};

function Root() {
  const [locale, setLocale] = useState("en");

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // initializePriceCache()
  return (
    <Provider store={store}>
      {renderMetadata(
        "MetaCRM Support Center",
        "Made with ♥️ in MetaVerse",
        window.location.href,
        null
      )}
      <StylesProvider>
        <CustomThemeProvider>
          <IntlProvider
            locale={locale}
            defaultLocale="en"
            messages={messages[locale]}
          >
            <SnackbarProvider>
              <WagmiConfig config={wagmiConfig}>
                <WalletProvider
                  wallets={wallets}
                  autoConnect={true}
                  onError={(error) => {
                    console.log("Handle Aptos Error Message", error);
                  }}
                >
                  <ConnectionProvider endpoint={endpoint}>
                    <SolanaWalletProvider
                      wallets={solanaWallets}
                      autoConnect={true}
                      onError={(error) => {
                        console.log("Handle Solana Error Message", error);
                      }}
                    >
                      <RouterProvider router={router} />
                    </SolanaWalletProvider>
                  </ConnectionProvider>
                </WalletProvider>
              </WagmiConfig>
            </SnackbarProvider>
          </IntlProvider>
        </CustomThemeProvider>
      </StylesProvider>
    </Provider>
  );
}

export default hot(module)(Root);
